export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {

  //MENU
    'Inicio': 'Home',
    'AcercaDe': 'About',
    'InfoInstitucional': 'Institutional Information',
    'Creditos': 'Credits',
    'Contribuidores': 'Contributors',
    'Contacto': 'Contact',
    'ReporteFinal': 'Final Reports',
    'ReportesFinales': 'Final reports',
    'BdPaises':'Countries data base',

    //AcercaDeTitulos
    'acercaDeTitulos1':'Observatory Cybersecurity in Latin America and the Caribbean',

    //home
    'anio':'Año',
    'cerrar':'Close',
    'choseYear':'Choose year to compare',
    'detalle':'Detail',
    'leerMas':'Read more >>',
    'poblacionTotalDelPais':'Total Population',
    'personasAccesoInternet':'With Internet',
    'personasAccesoCelular':'With Phone',
    'penetracionInternet':'Internet Penetration',
    'descargar':'Download',
    'descargarPortugues':'Now available in Portuguese here',
    'compartir':'Share',
    'enviarVinculo':'Send the link of this page to',
    'compararPaises':'Compare countries',
    'compararPaisPorAnio':'Compare country by year',
    'comentarioOpcional':'Comment (Optional)',
    'mensajeOk':'Message sent successfully',
    'mensajeError':'Message don\'t sent',
    'seleccioneAnio':'Select year',
    'seleccionePais':'Select a country',
    'aviso': 'Notice',
    'apliqueFiltros':'Select all the variables of each of the options in the left panel to see the result.',
    'aviso1':'To see the results please select the options of the 3 steps',
    'avisoPasoUno':'Select countries to compare',
    'avisoPasoDos':'Select the year you want to compare',
    'avisoPasoTres':'Select specific indicator within each dimension',
    'aviso2':'Once you have selected the 3 steps you can see the desired results',
    'seleccioneParaVerDatos': 'Select country to view data',
    'opcionToCompare':'Select option to compare',
    'descargarLoSeleccionado':'Download the XLS of everything selected',
    'informacionDePaises':' Countries information',
    'paisesAComparar':'Countries to compare',
    'paisACompararPorAnios':'Countries to compare by year',

    //InstitutionalInformation
    'institutionalInfoTitulo':'Institutional information',

    //Contribuidores
    'contribuidoresParrafo1':'The Inter-American Development Bank (IDB) and the Organization of American States (OAS) would like to recognize the following organizations for their efforts and contributions to cyber security in the region and this report.',
    'contribuidoresParrafo2':'Recognition of the institutions mentioned above does not imply their endorsement of this publication.',
  
    //contacto
    'conTituloContact':'Contact',
    'conTituloFormCon':'Contact Form',
    'conSubTituOEA':'Organization of American States',
    'conSubTituOEA2':'Inter-American Development Bank',
    'contacEstadosUnidos':'United States of America',
    //contactoform
    'contacFormNom':'Name',
    'contacFormConsulta':'Query',
    'contacFormEnviar':'Send',

    //home
    'homeParrafo1':'Select the criteria you want to explore.',
    'homeInputBusqueda':'Search',
    'homeBtnCleanCountriesSelection':'Clean',
    'homeBtn1VerListPaises':'See results of the selected',
    'homeBtn2VerListPaises':'Return to the map',
    'homeSmallInicial':'Initial',
    'homeSmallFormativo':'Formative',
    'homeSmallEstablecido':'Settled down',
    'homeSmallEstrategico':'Strategic',
    'homeSmallDinamico':'Dynamic',
    'homeH6Capacidad':'Cybersecurity capacity',
    'homeH6NivelesMadurez':'Maturity levels',
    'homeSmallDatos':'The data used was collected through an online survey developed in collaboration with the Global Cyber ​​Security Capacity Center (GCSCC) on the basis of the Cyber ​​Security Capability Maturity Model (CMM), for its acronym in English. The 2016 values used were updated to reflect the Cybersecurity Capacity Maturity Model for Nations (CMM) Revised Edition. All assessments conducted in 2016 publication remains the same except for the inclusion of new indicators.',
    'homeTablePais':'Countries',
    'homeBtn2EliminarPais':'Remove country',

  // Paises
    'antiguaYbarbuda':'ANTIGUA Y BARBUDA',
    'argentina':'ARGENTINA',
    'lasBahamas':'THE BAHAMAS',
    'barbados':'BARBADOS',
    'belice':'BELIZE',
    'bolivia':'BOLIVIA',
    'brasil':'BRAZIL',
    'chile':'CHILE',
    'colombia':'COLOMBIA',
    'costaRica':'COSTA RICA',
    'dominica':'DOMINICA',
    'republicaDominicana':'DOMINICAN REPUBLIC',
    'ecuador':'ECUADOR',
    'elSalvador':'EL SALVADOR',
    'granada':'GRENADA',
    'guatemala':'GUATEMALA',
    'guyana':'GUYANA',
    'haiti':'HAITI',
    'honduras':'HONDURAS',
    'jamaica':'JAMAICA',
    'mexico':'MEXICO',
    'nicaragua':'NICARAGUA',
    'panama':'PANAMA',
    'paraguay':'PARAGUAY',
    'peru':'PERU',
    'sanCristobal':'SAINT KITTS AND NEVIS',
    'santaLucia':'SAINT LUCIA',
    'sanVicente':'SAINT VINCENT AND THE GRENADINES',
    'suriman':'SURINAME',
    'trinidadYtobago':'TRINIDAD AND TOBAGO',
    'uruguay':'URUGUAY',
    'venezuela':'VENEZUELA',
  
    /* Table detail */
    'policyAndStrategy' : 'Cybersecurity Policy and Strategy',
    'nationalCybersecurityStrategy' : 'National Cybersecurity Strategy',
    'strategyDevelopment' : 'Strategy Development',
    'organization' : 'Organization',
    'content' : 'Content',
    'incidentResponse' : 'Incident Response',
    'identificationOfIncidents' : 'Identification of Incidents',
    'coordination' : 'Coordination',
    'modeOfOperation' : 'Mode of Operation',
    'criticalInfrastructureProtection' : 'Critical Infrastructure (CI) Protection',
    'riskManagementAndResponse' : 'Risk Management and Response',
    'crisisManagement' : 'Crisis Management',
    'crisisDefense' : 'Crisis Defense',
    'strategy' : 'Strategy',
    'communicationsRedundancy' : 'Communications Redundancy',
    
    'cyberCultureAndSociety' : 'Cyber Culture and Society',
    'cyberSecurityMindSet' : 'Cyber Security Mind-set',
    'government' : 'Government',
    'privateSector' : 'Private Sector',
    'users' : 'Users',
    'trustConfidenceOnInternet' : 'Trust and Confidence on the Internet',
    'userTrustOnInternet' : 'User Trust and Confidence on the Internet',
    'userTrustEGovermmentOnServices' : 'User Trust in E-government Services',
    'userTrustEcommerceService' : 'User Trust in E-commerce Services',
    'userUnderstandingPersonalInformation' : 'User Understanding of Personal Information Protection Online',
    'reportingMechanisms' : 'Reporting Mechanisms',
    'mediaAndSocialMedia' : 'Media and Social Media',
    
    'educationTraining' : 'Cybersecurity Education, Training and Skills',
    'awarenessRaising' : 'Awareness Raising',
    'awarenessRaisingProgrammes' : 'Awareness Raising Programmes',
    'executiveAwarenessRaising' : 'Executive Awareness Raising',
    'frameworkForEducation' : 'Framework for Education',
    'administration' : 'Administration',
    'frameworkForProfessionalTraining' : 'Framework for Professional Training',
    'provision' : 'Provisión',
    'uptake' : 'Uptake',
    
    'legalAndRegulatoryFrameworks' : 'Legal and Regulatory Frameworks',
    'legalFrameworks' : 'Legal Frameworks',
    'legislativeFrameworks' : 'Legislative frameworks for ICT Security',
    'humanRightsOnline' : 'Privacy, Freedom of Speech and other Human Rights online',
    'dataProtectionLegislation' : 'Data Protection Legislation',
    'childProtectionOnline' : 'Child Protection Online',
    'consumerProtectionLegislation' : 'Consumer Protection Legislation',
    'intellectualPropertyLegislation' : 'Intellectual Property Legislation',
    'substantiveCybercrimeLegislation' : 'Substantive cybercrime Legislation',
    'proceduralCybercrimeLegislation' : 'Procedural cybercrime Legislation',
    'criminalJusticeSystem' : 'Criminal Justice System',
    'lawEnforcement' : 'Law enforcement',
    'prosecution' : 'Prosecution',
    'courts' : 'Courts',
    'formalAndInformalCombatCybercrime' : 'Formal and Informal Cooperation Frameworks to Combat Cybercrime',
    'formalCooperation' : 'Formal Cooperation',
    'informalCooperation' : 'Informal Cooperation',
    
    'technologies' : 'Standards, Organizations, and Technologies',
    'adherenceToStandards' : 'Adherence to standards',
    'securityStandardsICT' : 'ICT Security Standards',
    'standardsProcurement' : 'Standards in Procurement',
    'standardsSoftwareDevelopment' : 'Standards in Software Development',
    'internetInfrastructureResilience' : 'Internet Infrastructure Resilience',
    'softwareQuality' : 'Software Quality',
    'technicalSecurityControls' : 'Technical Security Controls',
    'cryptographicControls' : 'Cryptographic Controls',
    'cyberSecurityMarketplace' : 'Cyber Security Marketplace',
    'cyberSecurityTechnologies' : 'Cyber Security Technologies',
    'cybercrimeInsurance' : 'Cybercrime Insurance',
    'responsibleDisclosure' : 'Responsible Disclosure',
  
};

