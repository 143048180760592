import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {AboutComponent} from './components/about/about.component';
import {CreditsComponent} from './components/credits/credits.component';
import {ContributorsComponent} from './components/contributors/contributors.component';
import {ContactComponent} from './components/contact/contact.component';
import {InstitutionalInformationComponent} from './components/institutionalinformation/institutionalinformation.component';
import {FinalReportComponent} from './components/final-report/final-report.component';


const APP_ROUTES: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'credits', component: CreditsComponent },
  { path: 'contributors', component: ContributorsComponent },
  { path: 'institutionalinformation', component: InstitutionalInformationComponent },
  { path: 'contact', component: ContactComponent},
  { path: 'final-report', component: FinalReportComponent},
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true, relativeLinkResolution: 'legacy' });
