import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../services/translateService'

@Component({
  selector: 'app-final-report',
  templateUrl: './final-report.component.html',
  styles: []
})
export class FinalReportComponent implements OnInit {

  constructor(public _translate: TranslateService) { }

  ngOnInit() {
  }

}
