import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from './global';

@Injectable()
export class CyberSecurityContentService {

  constructor(public http: HttpClient) {}
  urlAbout = `${GLOBAL.url}/cybersecurity/about`;
  headers = new HttpHeaders()

  getAboutInformation(){
    return this.http.get(this.urlAbout, {headers: this.headers})
  }

}
