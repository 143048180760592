<div class="container-fluid pr-0">
  <app-header></app-header>
  <div class="row mr-0">
    <div class="col-2  col-xs-1  col-sm-4 col-md-4 col-lg-2 pr-0 left-side">
      <app-menu></app-menu> <!-- menu general-->
    </div>
    <div class="col-10 col-xs-11 col-sm-8 col-md-8 col-lg-10 right-side">
      <router-outlet>
      </router-outlet>
    </div>
  </div>
</div>
