import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import{ APP_ROUTING } from "./app.routes"

// Captcha
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

// to use get or post rest api
import { HttpClientModule} from "@angular/common/http";

//services
import { CountryService } from './services/country.service';
import { AttributeService } from './services/attribute.service';
import { CountryInfoService } from './services/countryInfo.service';

import { EmailService } from './services/email.service';
import { TranslateService } from './services/translateService';
import { ExcelService } from './services/excel.service';
import { CyberSecurityContentService } from './services/cyberSecurityContent.service';
import { CyberSecurityInstitutionalService} from './services/cyberSecurityInstitutionalInfo.service';
import { CyberSecurityCreditService } from './services/cyberSecurityCredits.service';
import { CyberSecurityContributorService } from './services/cyberSecurityContributor.service';
import { StatusComponentsService } from  './services/statusComponents.service';

import { TRANSLATION_PROVIDERS } from './translations/translation';

//pipes
import {TranslateCountryNamePipe } from './pipes/translateCountryNamePipe';
import {TranslatePipe } from './pipes/translatePipe';
import {GroupByPipe } from './pipes/groupByPipe';
import {FilterPipe } from './pipes/FilterPipe';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { BodyComponent } from './components/body/body.component';
import { HomeComponent } from './components/home/home.component';
import { TableDetailComponent } from './components/home/table-detail/tableDetail.component';
import { AboutComponent } from './components/about/about.component';
import { CreditsComponent } from './components/credits/credits.component';
import { ContributorsComponent } from './components/contributors/contributors.component';
import { ContactComponent } from './components/contact/contact.component';
import { InstitutionalInformationComponent } from './components/institutionalinformation/institutionalinformation.component';
import { MenuComponent } from './components/menu/menu.component';
import { InfoCountrySelectedComponent } from './components/home/info-country-selected/info-country-selected.component';
import { FinalReportComponent } from './components/final-report/final-report.component';
import { CompartirComponent } from './components/home/compartir/compartir.component';
import { MenuHomeComponent } from './components/home/menu-home/menu-home.component';
import { MapaComponent } from './components/home/mapa/mapa.component';
import { DetallePaisComponent } from './components/home/detalle-pais/detalle-pais.component';
import { CaptchaService } from './services/captcha.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BodyComponent,
    HomeComponent,
    TableDetailComponent,
    MenuHomeComponent,
    TranslateCountryNamePipe,
    TranslatePipe,
    GroupByPipe,
    FilterPipe,
    AboutComponent,
    CreditsComponent,
    ContributorsComponent,
    ContactComponent,
    InstitutionalInformationComponent,
    MenuComponent,
    InfoCountrySelectedComponent,
    FinalReportComponent,
    CompartirComponent,
    MapaComponent,
    DetallePaisComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    Ng2CarouselamosModule,
    APP_ROUTING,
    LeafletModule.forRoot(),
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
  ],
  providers: [
    CountryService,
    AttributeService,
    CountryInfoService,
    TranslateService,
    EmailService,
    ExcelService,
    CyberSecurityContentService,
    CyberSecurityInstitutionalService,
    CyberSecurityCreditService,
    CyberSecurityContributorService,
    StatusComponentsService,
    CaptchaService,
    TRANSLATION_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
