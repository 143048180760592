import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../services/country.service'
@Pipe({
  name: 'filter',
  pure: false
})

export class FilterPipe implements PipeTransform {
  transform(items: Country[], field : string, value : string): any {
    if (!items || !field || !value) {
        return items;
    }
    return items.filter(i=> i[field].toString() === value.toString());
  }
}
