<div class="container-fluid margin-top pl-0">
  <!-- home -->
  <div class="row">
    <!-- formulario filtrar paises -->
    <div class="col-md-5 col-sm-10 borderRight">
      <menu-home
        (countrySelectedToSend)="paintCountry($event)"
        (executeCleanMap)="cleanMap()"
      ></menu-home>
    </div>

    <!-- mapa y countries info -->
    <!--<div class="col-7 pl-2" [hidden]="_countryService.showingDetail">-->
    <div class="col-md-7 col-sm-10 pl-2" *ngIf="!_countryService.showingDetail">
      <div class="rTable">
        <div class="rTableRow d-none d-lg-inline">
          <div class="rTableCell" style="position: relative; z-index: 0;">
            <app-mapa
              (countriesSelectedToSendToCheckBox)="checkCountryByMapa($event)"
            ></app-mapa>
          </div>
        </div>
        <div class="rTableRow">
          <div class="rTableCell">
            <app-info-country-selected></app-info-country-selected>
          </div>
        </div>
      </div>
    </div>

    <!-- tabla detalles -->
    <!--<div class="col-7 pl-4" [hidden]="!_countryService.showingDetail">-->
    <div class="col-md-7 col-sm-10 pl-4" *ngIf="_countryService.showingDetail">
      <app-table-detail></app-table-detail>
    </div>

    <!-- Button of the right side 'ver resultado de lo selecionado'-->
    <button
      (click)="setShowDetail()"
      *ngIf="!_countryService.showingDetail"
      class="detail left btn-primary"
    >
      <span class="size-12">{{ "homeBtn1VerListPaises" | translate }}</span>
    </button>
  </div>
</div>

<script type="text/javascript">
  jQuery(document).ready(function () {
    jQuery(".main-table")
      .clone(true)
      .appendTo("#table-scroll")
      .addClass("clone");
  });
</script>
