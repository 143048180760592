import { Component, OnInit } from '@angular/core';
import {CyberSecurityContentService} from '../../services/cyberSecurityContent.service';
import { TranslateService } from '../../services/translateService';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {
  about:any ={};
  constructor(public _cybersecurityService : CyberSecurityContentService,
              public _translate: TranslateService){ }

  ngOnInit() {
    this.getAbout();
  }

  getAbout(){
    if(JSON.stringify(this.about)==="{}"){
      this._cybersecurityService.getAboutInformation().subscribe(result => {
        this.about=result;
      });
    }
  }

  isCurrentLang(lang: string) {
      return lang === this._translate.currentLang;
  }
}
