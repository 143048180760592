import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from './global';

@Injectable()
export class CyberSecurityCreditService {

  constructor(public http: HttpClient) {}
  url = `${GLOBAL.url}/cybersecurity/credit`;
  headers = new HttpHeaders()

  getCredits(){
    return this.http.get(this.url, {headers: this.headers})
  }

}
