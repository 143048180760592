import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from './global';

@Injectable()
export class CyberSecurityContributorService {

  constructor(public http: HttpClient) {}
  url = `${GLOBAL.url}/cybersecurity/contributor`;
  headers = new HttpHeaders()

  getContributors(){
    return this.http.get(this.url, {headers: this.headers})
  }

}
