<div
  class="nav nav-pills margin-top mr-sm-4"
  id="v-pills-tab"
  aria-orientation="vertical"
  style="position: relative;"
>
  <div
    class="col-12 pr-1 pl-1"
    *ngFor="let i of itemsMenu"
    style="overflow: hidden;"
  >
    <a
      [className]="
        isActive(i.item)
          ? 'nav-link pt-0 pb-4 pl-1 pr-1 active'
          : 'nav-link pt-0 pb-4 pl-1 pr-1'
      "
      data-toggle="pill"
      (click)="setItemActive(i.item)"
      [routerLink]="[i.rlink]"
      role="tab"
    >
      <!--id="v-pills-home-tab"-->
      <tr style="vertical-align: middle;">
        <td>
          <img
            class="mr-1"
            width="40"
            height="auto"
            *ngIf="!isActive(i.item)"
            src="{{ i.image }}"
          />
          <img
            class="mr-1"
            width="40"
            height="auto"
            *ngIf="isActive(i.item)"
            src="{{ i.imageActive }}"
          />
        </td>
        <td>
          <label class="d-none d-sm-inline">{{ i.item | translate }}</label>
        </td>
      </tr>
    </a>
  </div>
  <div class="col-12 pr-1" style="position: absolute; bottom: -35%;">
    <p class="d-none d-md-inline ml-2" style="color: #6b737a;">
      <small>© Copyright 2020</small>
    </p>
  </div>
</div>
