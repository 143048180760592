import { Injectable, SystemJsNgModuleLoader } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { map } from 'rxjs/operators';
import { GLOBAL } from './global';
import { CountryInfoService, CountryInfo } from '../services/countryInfo.service'
import { AttributeService } from '../services/attribute.service'

declare var $:any;

@Injectable()
export class CountryService {

  constructor(public http: HttpClient,
              public _countryInfoService: CountryInfoService,
              public _attributeService: AttributeService) {}

  url = `${GLOBAL.url}/countries`;
  compareOneCountryByYears:Boolean= false;  

  allCountries:Country[]=[];
  countriesSelected:Country[]=[];
  yearsToCompare:string[]=[];
  showingDetail: boolean=false;
  yearSelected="null";
  countrySelected:string=''

  getAllCountries(){
    this.countriesSelected= [];
    return this.http.get(this.url).pipe(
      map( (res: any) => {
      this.allCountries=res.country;
      this.allCountries.forEach(_=> _.selected= false)
      return this.allCountries
    }));
  }

  getCountriesSelectedISO(){
    let countriesSelectedISO:string[]=[]
    for (let country of this.allCountries.filter(country => country.selected == true)){
      if(!countriesSelectedISO.includes(country.iso_alpha2)) countriesSelectedISO.push(country.iso_alpha2)
    }
    return countriesSelectedISO
  }

  selectCountry(iso:string) {
    if(this.isCountrySelected(iso)){
      this.deleteCountrySelected(iso)
    }else{
      this.allCountriesByIso(iso).forEach(_ => _.selected= true);
    }
    this.updateCountriesSelected();
  }

  thereAreCountriesSelected(){
    return this.allCountries.filter(_ => _.selected== true).length>0
  }


  updateCountriesSelected(){
    this.countriesSelected=[];
    this._countryInfoService.countriesInfoSelected=[];
    
    if(this.thereAreCountriesSelected()){
      this.allCountries.filter(_ => _.selected== true).forEach(country => {
        this._countryInfoService.addCountryInfoSelected(country.iso_alpha2)
      })
      if(this.yearsToCompare.length > 0 && this._attributeService.thereAreSubAttributesToCompare()){
        this.allCountries.filter(_ => _.selected== true).forEach(country => {
          if(this.isYearChecked(country.year)){
            this.countriesSelected.push(country)
          }
        })
      }
    }else this.showingDetail = false;

  }

  deleteCountrySelected(iso:string) {
    this.allCountriesByIso(iso).forEach(_ => _.selected= false);
    this._countryInfoService.removeCountryInfoSelected(iso);
    this.updateCountriesSelected();
  }

  cleanSelectedCountries(){
    this.cleanYearsToCompare()
    this.allCountries.forEach( c => this.deleteCountrySelected( c.iso_alpha2 ) );
    this.updateCountriesSelected();
  }

  allCountriesByIso(iso: string) {
    return this.allCountries.filter(c => c.iso_alpha2 == iso)
  }

  isCountrySelected(iso: string) {
    return this.allCountries.filter(c => c.iso_alpha2 == iso && c.selected== true).length > 0
  }

  isYearChecked(year: string){
    if(this.yearsToCompare.filter(_=> _ === year).length>0) return true; else return false;
  }

  cleanYearsToCompare(){
    this.yearsToCompare=[];
    this.yearSelected="null";
    this.updateCountriesSelected();
  }

  deleteYearToCompare(year:string){
    this.yearsToCompare = this.yearsToCompare.filter(_=> _ != year)
  }

  addYearToCompare(year:string){
    this.yearsToCompare.push(year);
    this.updateCountriesSelected();
  }

  showDetail(){
    var totalPaises = this.allCountries.filter(_ => _.selected== true).length;
    var totalAnios = this.yearsToCompare.length
    var totalAtributos = this._attributeService.subAttributesToCompare.length;
    if(totalPaises==0 || totalAnios ==0 || totalAtributos==0){
      console.error("Falta información --> Paises: "+ totalPaises + ", años: "+ totalAnios + ", atributos: " + totalAtributos)
      $(document).ready(function(){ $('#myModal2').modal('show'); });
    }else
      this.showingDetail=!this.showingDetail
  }

  isShowDetail(){
    return this.showingDetail
  }
}

export interface Country {
  _id: string;
  nameEs: string;
  nameEn: string;
  iso_alpha2: string,
  year: string;
  lastUpdate: string,
  image: string,
  selected: boolean,
  attributes: Object
}
