import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CountryService, Country } from '../../../services/country.service'
import { AttributeService } from '../../../services/attribute.service'
import { CountryInfoService, CountryInfo } from '../../../services/countryInfo.service'
import { TranslateService } from '../../../services/translateService'
import { ExcelService} from '../../../services/excel.service';
import { Router, NavigationEnd } from '@angular/router';

declare var $ :any;
@Component({
  selector: 'menu-home',
  templateUrl: './menu-home.component.html',
  styleUrls: ['./menu-home.component.css']
})
export class MenuHomeComponent implements OnInit {

  @Output() countrySelectedToSend = new EventEmitter<string>();
  @Output() executeCleanMap = new EventEmitter();

  //mySubscription:any;

  ngOnInit() {}

  ngAfterViewInit(){
    for (let countrySelectedIso of this._countryService.getCountriesSelectedISO()) this.sendCountryToMap(countrySelectedIso)
  }

  constructor(public _countryService: CountryService,
              public _countryInfoService: CountryInfoService,
              public _attributeService: AttributeService,
              public _translate: TranslateService,
              public _excelService:ExcelService,
              public _router:Router) {
  }

  selectCountry(iso: string) {
    if(this._countryService.compareOneCountryByYears) {
      this._countryService.cleanSelectedCountries();
    }
    this._countryService.selectCountry(iso);
    this._countryService.countrySelected = iso
    this.sendCountryToMap(iso)
  }

  cleanAllFilters(){
    this._countryService.cleanYearsToCompare(); 
    this._countryService.cleanSelectedCountries();
    this._attributeService.cleanSubAttributesToCompare();
    this.executeCleanMap.emit();
    this.redirectTo('/home');
  }

  selectYearToCompare(year: string){
    if(this._countryService.compareOneCountryByYears) {
      if(this._countryService.isYearChecked(year)) this._countryService.deleteYearToCompare(year)
      else this._countryService.addYearToCompare(year)
    } else {
      this._countryService.cleanYearsToCompare();  
      this._countryService.addYearToCompare(year);
    }
    this._countryService.updateCountriesSelected();
  }

  selectAttributeToCompare(attribute: string){
    if(this._attributeService.isAttributeSeleted(attribute)){
      this._attributeService.deleteAttributeToCompare(attribute)
      this._attributeService.attributes.find(_=> _.name == attribute).selected = false
      this._attributeService.deleteSubAttributeToCompareByAttribute(attribute)
    }
    else{
      this._attributeService.attributesToCompare.push(attribute)
      this._attributeService.attributes.find(_=> _.name == attribute).selected = true
      this._attributeService.addSubAttributeToCompareByAttribute(attribute)
    }
    this._countryService.updateCountriesSelected();
  }

  selectSubAttributeToCompare(subAttribute: string){
    if(this._attributeService.isSubAttributeSeleted(subAttribute))
      this._attributeService.deleteSubAttributeToCompare(subAttribute)
    else
      this._attributeService.subAttributesToCompare.push(subAttribute);
    this._countryService.updateCountriesSelected();
  }

  compareOneCountry(value: Boolean){
    if(this._countryService.compareOneCountryByYears != value){
      this._countryService.cleanSelectedCountries();
      this.executeCleanMap.emit();
      this._countryService.countrySelected = null
    }
    this._countryService.compareOneCountryByYears = value;
    this._countryService.showingDetail = false;
  }

  sendCountryToMap(isoCountry: string){
    if(this._countryService.compareOneCountryByYears){
      this.executeCleanMap.emit();
      this.countrySelectedToSend.emit(isoCountry);
    } else {
      this.countrySelectedToSend.emit(isoCountry);
    }
  }

  redirectTo(uri:string){
    this._router.navigateByUrl('/about', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
  }

}