<div class="margin-top">
    <h3>{{ 'Contribuidores' | translate }} </h3>
    <pre class="text-justify">{{ 'contribuidoresParrafo1' | translate }}</pre>
    <pre class="text-justify">{{ 'contribuidoresParrafo2' | translate }}</pre>

    <table class="table">
      <tbody>
        <tr *ngFor="let contributor of contributors; let i=index; let par = even;">
          <td *ngIf="par" class="mr-0 pr-0">
            <p [hidden]="isCurrentLang('en')"><b>{{ contributors[i].contributorEs }}</b></p>
            <p [hidden]="isCurrentLang('es')"><b>{{ contributors[i].contributorEn }}</b></p>
            <pre [hidden]="isCurrentLang('en')">{{ contributors[i].descriptionEs }}</pre>
            <pre [hidden]="isCurrentLang('es')">{{ contributors[i].descriptionEn }}</pre>
          </td>
          <td *ngIf="par && i+1<=contributors.length" >
            <p [hidden]="isCurrentLang('en')"><b>{{ contributors[i+1].contributorEs }}</b></p>
            <p [hidden]="isCurrentLang('es')"><b>{{ contributors[i+1].contributorEn }}</b></p>
            <pre [hidden]="isCurrentLang('en')">{{ contributors[i+1].descriptionEs }}</pre>
            <pre [hidden]="isCurrentLang('es')">{{ contributors[i+1].descriptionEn }}</pre>
          </td>
        </tr>
      </tbody>
    </table>
</div>
