<div class="container-fluid">
  <div class="row size-11">
    <div class="center">
      <a class="mr-4" href="{{urlToShareTwiter}}" target="_blank" style="color: #6b737a">
        <i class="fab fa-twitter fa-lg"></i> Twiter
      </a>
      <a href="{{urlToShareFacebook}}" target="_blank" style="color: #6b737a">
        <i class="fab fa-facebook-f fa-lg"></i> Facebook
      </a>
    </div>
  </div>

  <div class="row">
      <p style="color:#545454;" class="mt-1"><b>{{'enviarVinculo' | translate}}</b></p>
  </div>

  <div class="row">
    <div class="col-12 px-0">
      <form  #myForm="ngForm" (ngSubmit)="sendShareEmail(); myForm.reset()" ngNativeValidate>
        <div class="form-group">
            <input type="email" class="form-control size-11" placeholder="Email" name="email" required [(ngModel)]="compartir.email" >
        </div>
        <div class="form-group">
          <textarea class="form-control size-11" id="exampleFormControlTextarea1" placeholder="{{'comentarioOpcional' | translate}}" rows="3" name="comment" required [(ngModel)]="compartir.comment"></textarea>
        </div>
        <div>
          <re-captcha  name="captcha" 
            [siteKey]="siteKey" required [(ngModel)]="compartir.captcha"></re-captcha>
        </div>
        <button type="submit" class="btn btn-primary mt-2 size-11"> {{'contacFormEnviar' | translate}} </button>
      </form>
      <div class="alert alert-success" role="alert" style="display: none" id="alert">
        <button type="button" class="close size-11" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <strong>{{'mensajeOk' | translate}}</strong>
      </div>
      <div class="alert alert-warning" role="alert" style="display: none" id="alert-warning">
        <button type="button" class="close size-11" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <strong>{{'mensajeError' | translate}}</strong>
      </div>
    </div>
  </div>
</div>
