import { Component, OnInit } from '@angular/core';
import { CountryService, Country } from '../../../services/country.service'
import { CountryInfoService, CountryInfo } from '../../../services/countryInfo.service'
import { TranslateService } from '../../../services/translateService'
import { ExcelService } from '../../../services/excel.service';
import { AttributeService, Attribute } from '../../../services/attribute.service';
import { combineAll } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
declare var $: any;

@Component({
  selector: 'app-table-detail',
  templateUrl: './tableDetail.component.html',
  styleUrls: ['./tableDetail.component.css']
})
export class TableDetailComponent implements OnInit {

  countryInfoEmpty: CountryInfo = {
    "_id": "",
    "nameEs": "",
    "nameEn": "",
    "iso_alpha2": "",
    "totalPopulation": "",
    "peopleWithInternet": "",
    "peopleWithPhones": "",
    "internetPenetration": "",
    "infoEs": [],
    "infoEn": [],
  }
  countryInfo: CountryInfo = this.countryInfoEmpty;

  constructor(public _countryService: CountryService,
    public _countryInfoService: CountryInfoService,
    public _translate: TranslateService,
    public _excelService: ExcelService,
    public _attributeService: AttributeService) {
  }

  ngOnInit() { }

  ngAfterViewInit(){
    $.getScript('assets/js/reset-dragscroll.js', function(){});
  }

  deleteCountrySelected(iso: string) {
    this._countryService.deleteCountrySelected(iso);
  }

  getUrlFlat(iso: string) {
    if (!iso) iso = "NONE"
    return "assets/img/banderas/" + iso + ".png";
  }

  exportPdf(iso_alpha2:string, year:string) {
    var fileUrl = "assets/pdf/"+year+"/"+ this._translate.currentLang + "/" + iso_alpha2 + ".pdf";
    var fileName = iso_alpha2 + "_" + year + ".pdf"
    var blob = new Blob([fileUrl], {type: 'application/pdf'});
    FileSaver.saveAs(fileUrl,fileName)
  }

  exportAsXLSX(iso_alpha2) {
    let isos_alpha2: string[] = [iso_alpha2];
    let years: string[] = this._countryService.countriesSelected.map( c => c.year );
    this._excelService.exportAsExcelFile(isos_alpha2, years, 'Cybersecurity');
  }

  exportAllAsXLSX() {
    let isos_alpha2: string[] = this._countryService.countriesSelected.map( c => c.iso_alpha2 );
    let years: string[] = this._countryService.countriesSelected.map( c => c.year );
    this._excelService.exportAsExcelFile(isos_alpha2, years, 'Cybersecurity');
  }

  isSubAttributeselected(subatributes: string[]) {
    var isSelected = false
    subatributes.map(subAtrubute => {
      if (!isSelected) { isSelected = this._attributeService.isSubAttributeSeleted(subAtrubute) }
    });
    return isSelected;
  }

  isAttributeselected(atribute: Attribute) {
    return this.isSubAttributeselected(atribute.subAttributes.map(_ => _.name))
  }

  buildDataTarget(atribute: Attribute, idxAttribute){
    var idxSubAttribute = 0
    var target = ""
    atribute.subAttributes.forEach(_=>{
      if(idxSubAttribute==0) target = target + " #att" + idxAttribute + "-" + idxSubAttribute 
      else target = target + ", #att" + idxAttribute + "-" + idxSubAttribute 
      idxSubAttribute = idxSubAttribute + 1;
    })
    return target
  }

  counter(i: number) { return new Array(i); }

  showDetails(iso_alpha2: string) {
    var selectedCountry = this._countryInfoService.countriesInfoSelected.find(c => c.iso_alpha2 == iso_alpha2);
    var filteredCountries = this._countryInfoService.countriesInfoSelected.filter(c => c.iso_alpha2 != iso_alpha2);
    this._countryInfoService.countriesInfoSelected = [selectedCountry].concat(filteredCountries);
  }

  updatePopupInfo(iso: string){
    this._countryInfoService.popupCountriesInfoSelected = this._countryInfoService.allCountriesInfo.filter(c => c.iso_alpha2 == iso)
    if(this._countryInfoService.popupCountriesInfoSelected.length==1)
      this._countryInfoService.countryInfoSelected = this._countryInfoService.popupCountriesInfoSelected[0]
  }

  goBackToMap() {
    this._countryService.showDetail();
  }

}
