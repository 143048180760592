import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from './global';

@Injectable()
export class CyberSecurityInstitutionalService {

  constructor(public http: HttpClient) {}
  urlInstitucionalInformation = `${GLOBAL.url}/cybersecurity/InstitucionalInformation`;
  headers = new HttpHeaders()

  getInstitucionalInformation(){
    return this.http.get(this.urlInstitucionalInformation, {headers: this.headers})
  }

}
