import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../services/country.service'
@Pipe({
  name: 'groupBy',
  pure: false
})

export class GroupByPipe implements PipeTransform {
  countriesFiltered:Country[]=[];
  
  transform(items: Array<Country>, field: string): Array<Country> {
    this.countriesFiltered=[];
    if (!items || !field) {
      return this.countriesFiltered;
    }
    const groupedObj = items.reduce((prev, cur)=> {
      if(!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {});

    Object.keys(groupedObj).map(key => {
      this.countriesFiltered.push(groupedObj[key][0]);
    });
    return this.countriesFiltered;
  }
}
