import { Component, OnInit } from "@angular/core";
import {
  CountryInfoService,
  CountryInfo,
} from "../../../services/countryInfo.service";
import { CountryService, Country } from "../../../services/country.service";
import { TranslateService } from "../../../services/translateService";
import { ExcelService } from "../../../services/excel.service";

@Component({
  selector: "app-detalle-pais",
  templateUrl: "./detalle-pais.component.html",
  styleUrls: ["./detalle-pais.component.css"],
})
export class DetallePaisComponent implements OnInit {
  ngOnInit() {}
  constructor(
    public _countryInfoService: CountryInfoService,
    public _translate: TranslateService
  ) {}

  getUrlFlat(iso: string) {
    if (!iso) iso = "NONE";
    return "assets/img/banderas/" + iso + ".png";
  }

  collapseButton(iso_alpha2: string) {
    this._countryInfoService.countryInfoSelected = this._countryInfoService.countriesInfoSelected.filter(
      (c) => c.iso_alpha2 == iso_alpha2
    )[0];
  }
}
