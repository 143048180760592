<div class="col-12">
  <!-- information table -->
  <div class="row borderBottom pb-2">
    <div class="col-md-3 col-sm-10 pr-0 mr-0 borderRight">
      <table
        margin="40px"
        style="border-collapse: separate; border-spacing: 0px;"
      >
        <tr>
          <p class="mb-1 size-12">
            <b>{{ "homeH6NivelesMadurez" | translate }}</b>
          </p>
        </tr>
        <tr>
          <p class="mb-0 size-10 interlineadoSmall">
            {{ "homeSmallInicial" | translate }}
          </p>
        </tr>
        <tr>
          <p class="mb-1 interlineadoSmall">
            <span class="points" *ngFor="let in of counter(1)"></span
            ><span class="grey-points" *ngFor="let in of counter(4)"></span>
          </p>
        </tr>
        <tr>
          <p class="mb-0 size-10 interlineadoSmall">
            {{ "homeSmallFormativo" | translate }}
          </p>
        </tr>
        <tr>
          <p class="mb-1 interlineadoSmall">
            <span class="points" *ngFor="let in of counter(2)"></span
            ><span class="grey-points" *ngFor="let in of counter(3)"></span>
          </p>
        </tr>
        <tr>
          <p class="mb-0 size-10 interlineadoSmall">
            {{ "homeSmallEstablecido" | translate }}
          </p>
        </tr>
        <tr>
          <p class="mb-1 interlineadoSmall">
            <span class="points" *ngFor="let in of counter(3)"></span
            ><span class="grey-points" *ngFor="let in of counter(2)"></span>
          </p>
        </tr>
        <tr>
          <p class="mb-0 size-10 interlineadoSmall">
            {{ "homeSmallEstrategico" | translate }}
          </p>
        </tr>
        <tr>
          <p class="mb-1 interlineadoSmall">
            <span class="points" *ngFor="let in of counter(4)"></span
            ><span class="grey-points" *ngFor="let in of counter(1)"></span>
          </p>
        </tr>
        <tr>
          <p class="mb-0 size-10 interlineadoSmall">
            {{ "homeSmallDinamico" | translate }}
          </p>
        </tr>
        <tr>
          <p class="mb-0 interlineadoSmall">
            <span class="points" *ngFor="let in of counter(5)"></span>
          </p>
        </tr>
      </table>
    </div>
    <div class="col-9 pr-0 d-none d-md-inline mb-2">
      <p class="mb-0 size-12">
        <b>{{ "homeH6Capacidad" | translate }}</b>
      </p>
      <p class="size-11">{{ "homeSmallDatos" | translate }}</p>
      <p class="mb-0 size-12">
        <b>{{ "descargarLoSeleccionado" | translate }}</b>
      </p>

      <button class="buttonSimple px-0" (click)="exportAllAsXLSX()">
        <img
          src="/assets/img/home/descargar-es.jpg"
          [hidden]="_translate._currentLang === 'en'"
          width="150"
        />
        <img
          src="/assets/img/home/descargar-en.jpg"
          [hidden]="_translate._currentLang === 'es'"
          width="150"
        />
      </button>
      <button
        class="buttonSimple float-right px-0"
        (click)="_countryService.showDetail()"
      >
        <img
          src="/assets/img/home/volver-mapa-es.png"
          [hidden]="_translate._currentLang === 'en'"
          width="130"
        />
        <img
          src="/assets/img/home/volver-mapa-en.jpg"
          [hidden]="_translate._currentLang === 'es'"
          width="130"
        />
      </button>
    </div>
  </div>
  <!-- Fin information table -->

  <!-- Table-detail -->
  <div class="row pt-1">
    <div class="col-12 overflowHidden pl-0">
      <div class="table-detail dragscroll size-13">
        <div class="row size-11">
          <table class="table green">
            <thead>
              <tr>
                <td
                  [colSpan]="_countryService.countriesSelected.length + 1"
                  class="pl-0"
                >
                  <table>
                    <tr style="border-bottom: 1px solid #ddd;">
                      <td
                        class="headcolTitle padding-30 size-13"
                        *ngIf="
                          _countryService.compareOneCountryByYears &&
                          _countryService.countriesSelected.length > 0
                        "
                      >
                        <p>
                          <b>{{ "paisACompararPorAnios" | translate }}</b>
                        </p>
                        <p class="size-14">
                          <b class="letterBlue">{{
                            _countryService.countriesSelected[0].nameEn
                              | translateCountryName
                                : _countryService.countriesSelected[0].nameEn
                                : _countryService.countriesSelected[0].nameEs
                          }}</b>
                        </p>
                      </td>
                      <td
                        class="headcolTitle padding-30 size-13 ml-2"
                        *ngIf="
                          !_countryService.compareOneCountryByYears &&
                          _countryService.countriesSelected.length > 0
                        "
                      >
                        <p>
                          <b>{{ "paisesAComparar" | translate }}</b>
                        </p>
                        <p>
                          <b>{{ "anio" | translate }} : </b
                          ><b class="letterBlue">
                            {{ _countryService.countriesSelected[0].year }}</b
                          >
                        </p>
                      </td>
                      <td
                        class="headcolItems"
                        *ngFor="
                          let countrySelected of _countryService.countriesSelected
                        "
                      >
                        <!-- country name -->
                        <tr style="height: 50px;">
                          <p class="modal-title">
                            <tr>
                              <td
                                class="pr-1 pl-0"
                                style="vertical-align: middle;"
                              >
                                <img
                                  src="/assets/img/home/cerrar_pais.png"
                                  class="float-left mr-1"
                                  (click)="
                                    deleteCountrySelected(
                                      countrySelected.iso_alpha2
                                    )
                                  "
                                />
                              </td>
                              <td
                                class="pl-0 pr-1"
                                style="vertical-align: middle;"
                              >
                                <img
                                  [src]="getUrlFlat(countrySelected.iso_alpha2)"
                                  onError="this.src='assets/img/banderas/NONE.png';"
                                  height="30"
                                />
                              </td>
                              <td
                                class="pl-0 pr-1"
                                style="vertical-align: middle;"
                              >
                                {{
                                  countrySelected.nameEn
                                    | translateCountryName
                                      : countrySelected.nameEn
                                      : countrySelected.nameEs
                                }}
                              </td>
                            </tr>
                          </p>
                        </tr>
                        <!--country options -->
                        <tr>
                          <button
                            class="buttonSimple pl-4"
                            (click)="exportPdf(countrySelected.iso_alpha2, countrySelected.year)"
                          >
                            <img src="/assets/img/home/pdf.png" height="20" />
                          </button>
                          <button
                            class="buttonSimple pl-1"
                            (click)="exportAsXLSX(countrySelected.iso_alpha2)"
                          >
                            <img src="/assets/img/home/xls.png" height="20" />
                          </button>
                          <button
                            class="buttonSimple pl-1"
                            data-target="#modalDetail"
                            data-toggle="modal"
                            (click)="
                              updatePopupInfo(countrySelected.iso_alpha2)
                            "
                          >
                            <img src="/assets/img/home/+info.png" height="20" />
                          </button>
                        </tr>
                        <div
                          class="row pl-5"
                          *ngIf="
                            _countryService.compareOneCountryByYears == true
                          "
                        >
                          <p>
                            <b class="letterBlue">
                              {{ countrySelected.year }}</b
                            >
                          </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </thead>
          </table>
        </div>
        <div
          class="row size-11"
          *ngFor="let atributo of _attributeService.attributes; let i = index"
        >
          <table
            class="table {{ atributo.design }}"
            *ngIf="isAttributeselected(atributo)"
          >
            <tbody>
              <!-- attributes -->
              <tr
                data-toggle="collapse"
                [attr.data-target]="buildDataTarget(atributo, i)"
                aria-expanded="true"
              >
                <td class="headcolTitle">
                  <p>
                    <b
                      ><i class="fas fa-caret-right mr-1 size-14"></i>
                      <i class="fas fa-caret-down mr-1 size-14"></i>
                      <img
                        src="assets/img/home/{{ atributo.image }}.png"
                        class="px-1"
                      />{{ atributo.name | translate }}</b
                    >
                  </p>
                </td>
                <td
                  class="headcolItems"
                  *ngFor="let item of _countryService.countriesSelected"
                ></td>
              </tr>
              <!-- subAttributes and points-->
              <tr
                *ngFor="let subAt of atributo.subAttributes; let j = index"
                id="att{{ i }}-{{ j }}"
                class="collapse show"
              >
                <td
                  [colSpan]="_countryService.countriesSelected.length + 1"
                  *ngIf="isSubAttributeselected([subAt.name])"
                  class="pl-0 pt-0"
                >
                  <table>
                    <tr>
                      <td class="headcolSubAttributes">
                        <p>
                          <b>{{ subAt.name | translate }}</b>
                        </p>
                      </td>
                      <td
                        class="headcolItems"
                        *ngFor="let item of _countryService.countriesSelected"
                      ></td>
                    </tr>
                    <tr *ngFor="let detail of subAt.details; let odd = odd">
                      <td
                        [ngClass]="odd ? 'headcolTitle' : 'headcolTitle fondo'"
                      >
                        <p>{{ detail | translate }}</p>
                      </td>
                      <td
                        [ngClass]="
                          odd ? 'headcolItems pl-5' : 'headcolItems fondo pl-5'
                        "
                        *ngFor="
                          let countrySelected of _countryService.countriesSelected
                        "
                      >
                        <span
                          class="points"
                          *ngFor="
                            let in of counter(
                              countrySelected.attributes[atributo.name][
                                subAt.name
                              ][detail]
                            )
                          "
                          >&nbsp;</span
                        >
                        <span
                          class="grey-points"
                          *ngFor="
                            let in of counter(
                              5 -
                                countrySelected.attributes[atributo.name][
                                  subAt.name
                                ][detail]
                            )
                          "
                          >&nbsp;</span
                        >
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- Fin Table-detail -->

  <!-- Button of the left side 'volver al mapa'-->
  <button (click)="goBackToMap()" class="detail right btn-primary">
    <span class="size-12">{{ "homeBtn2VerListPaises" | translate }}</span>
  </button>
</div>

<!--Modal-->
<div class="modal" id="modalDetail" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <app-detalle-pais></app-detalle-pais>
  </div>
</div>
