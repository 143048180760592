
<div class="margin-top">
    <h3>{{ 'Creditos' | translate }} </h3>
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr *ngFor="let credit of credits; let i=index; let par = even">
            <td *ngIf="par" class="mr-0 pr-0">
              <p [hidden]="isCurrentLang('en')"><b>{{ credits[i].creditEs }}</b></p>
              <p [hidden]="isCurrentLang('es')"><b>{{ credits[i].creditEn }}</b></p>
              <pre [hidden]="isCurrentLang('en')">{{ credits[i].descriptionEs }}</pre>
              <pre [hidden]="isCurrentLang('es')">{{ credits[i].descriptionEn }}</pre>
            </td>
            <td *ngIf="par && i+1<=credits.length" >
              <p [hidden]="isCurrentLang('en')"><b>{{ credits[i+1].creditEs }}</b></p>
              <p [hidden]="isCurrentLang('es')"><b>{{ credits[i+1].creditEn }}</b></p>
              <pre [hidden]="isCurrentLang('en')">{{ credits[i+1].descriptionEs }}</pre>
              <pre [hidden]="isCurrentLang('es')">{{ credits[i+1].descriptionEn }}</pre>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
