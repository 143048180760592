import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { map } from 'rxjs/operators';
import { GLOBAL } from './global';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor(public http: HttpClient) { }



  downloadReportByCountry(isos_alpha2:string[], years:string[]){
    
    var requestBody = {
      isos_alpha2: isos_alpha2,
      years: years
    }

    let downloadUrl = `${GLOBAL.url}/country/download`;
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    return this.http.post(
      downloadUrl, 
      requestBody,  
      { headers: headers, responseType: 'blob'}
    ).pipe(
      map( (res: any ) => {
      return res;
    }));
  }

  public exportAsExcelFile(isos_alpha2: string[],years:string[], excelFileName: string): void {
    this.downloadReportByCountry(isos_alpha2,years).subscribe( report =>{
      FileSaver.saveAs(report, excelFileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
    } );
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
