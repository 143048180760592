export const LANG_ES_NAME = 'es';

export const LANG_ES_TRANS = {
    'Inicio': 'Inicio',
    'AcercaDe': 'Acerca de',
    'InfoInstitucional': 'Información Institucional',
    'Creditos': 'Créditos',
    'Contribuidores': 'Contribuidores',
    'Contacto': 'Contacto',
    'ReporteFinal': 'Reportes Finales',
    'ReportesFinales': 'Reportes finales',
    'BdPaises':'Base de datos de todos los paises',

    //AcercaDeTitulos
    'acercaDeTitulos1':'Observatorio de Ciberseguridad en América Latina y el Caribe',

    //home
    'anio':'Año',
    'cerrar':'Cerrar',
    'choseYear':'Selecciona año a comparar',
    'detalle':'Detalle',
    'leerMas':'Leer más >>',
    'poblacionTotalDelPais':'Población total del país',
    'personasAccesoInternet':'Con acceso a Internet',
    'personasAccesoCelular':'Con teléfono móvil',
    'penetracionInternet':'Penetración a Internet',
    'descargar':'Descargar',
    'descargarPortugues':'Ahora disponible en portugués aquí',
    'compartir':'Compartir',
    'enviarVinculo':'Enviar el enlace de esta página a',
    'compararPaises':'Comparar países',
    'compararPaisPorAnio':'Comparar país por año',
    'comentarioOpcional':'Comentario (Opcional)',
    'mensajeOk':'Mensaje enviado exitosamente',
    'mensajeError':'Message no enviado',
    'seleccioneAnio':'Seleccione el año',
    'seleccionePais':'Seleccione un pais',
    'aviso': 'Aviso',
    'apliqueFiltros':'Seleccione todas las variables de cada una de las opciones en el panel izquierdo para ver el resultado.',
    'aviso1':'Para ver los resultados por favor seleccione las opciones en los 3 pasos',
    'avisoPasoUno':'Seleccione los países a comparar',
    'avisoPasoDos':'Seleccione el año que desee comparar',
    'avisoPasoTres':'Seleccione por indicador concreto dentro de cada dimensión',
    'aviso2':'Una vez seleccionado los 3 pasos podrá ver los resultados deseados',
    'seleccioneParaVerDatos': 'Seleccione país para ver datos',
    'opcionToCompare':'Seleccione opción para comparar',
    'descargarLoSeleccionado':'Descargue el XLS de todo lo seleccionado',
    'informacionDePaises':' Información de países',

    //InstitutionalInformation
    'institutionalInfoTitulo':'Información Institucional',

    //Contribuidores
    'contribuidoresParrafo1':'El Banco Interamericano de Desarrollo (BID) y la Organización de los Estados Americanos (OEA) se permiten hacer un reconocimiento a las siguientes instituciones por sus esfuerzos y contribuciones ala seguridad cibernética en la región y a este informe.',
    'contribuidoresParrafo2':'El reconocimiento a las instituciones mencionadas anteriormente no implica que las mismas validen el contenido del presente documento.',
  
    //contacto
    'conTituloContact':'Contacto',
    'conTituloFormCon':'Formulario de Contacto',
    'conSubTituOEA':'Organización de los Estados Americanos',
    'conSubTituOEA2':'Banco Interamericano de Desarrollo',
    'contacEstadosUnidos':'Estados Unidos de América',
    //contactoform
    'contacFormNom':'Nombre',
    'contacFormConsulta':'Consulta',
    'contacFormEnviar':'Enviar',

    //home
    'homeParrafo1':'Seleccione los criterios que desee explorar.',
    'homeInputBusqueda':'Buscar',
    'homeBtnCleanCountriesSelection':'Limpiar',
    'homeBtn1VerListPaises':'Ver resultados de lo seleccionado',
    'homeBtn2VerListPaises':'Volver al mapa',
    'homeSmallInicial':'Inicial',
    'homeSmallFormativo':'Formativo',
    'homeSmallEstablecido':'Establecido',
    'homeSmallEstrategico':'Estratégico',
    'homeSmallDinamico':'Dinámico',
    'homeH6Capacidad':'Capacidad de seguridad cibernética',
    'homeH6NivelesMadurez':'Niveles de madurez',
    'homeSmallDatos':'Los datos utilizados se recogieron a travez de una encuesta en linea desarrollada en colaboración con el Centro Global de Capacidad sobre Seguridad Ciberneutica (GCSCC) sobre la base del Modelo de Madurez de Capacidad de Seguridad Cibernetica (CMM), por sus siglas en inglés. Los valores de 2016 utilizados se actualizaron para reflejar el Modelo de madurez de la capacidad de ciberseguridad para la edición revisada de las naciones (CMM). Todas las evaluaciones realizadas en la publicación de 2016 siguen siendo las mismas, excepto la inclusión de nuevos indicadores.',
    'homeTablePais':'Países',
    'paisesAComparar':'Países a comparar',
    'paisACompararPorAnios':'País a comparar por años',
    'homeBtn2EliminarPais':'Eliminar pais',

    // Paises
    'antiguaYbarbuda':'ANTIGUA Y BARBUDA',
    'argentina':'ARGENTINA',
    'lasBahamas':'LAS BAHAMAS',
    'barbados':'BARBADOS',
    'belice':'BELICE',
    'bolivia':'BOLIVIA',
    'brasil':'BRASIL',
    'chile':'CHILE',
    'colombia':'COLOMBIA',
    'costaRica':'COSTA RICA',
    'dominica':'DOMINICA',
    'republicaDominicana':'REPÚBLICA DOMINICANA',
    'ecuador':'ECUADOR',
    'elSalvador':'EL SALVADOR',
    'granada':'GRANADA',
    'guatemala':'GUATEMALA',
    'guyana':'GUYANA',
    'haiti':'HAITÍ',
    'honduras':'HONDURAS',
    'jamaica':'JAMAICA',
    'mexico':'MÉXICO',
    'nicaragua':'NICARAGUA',
    'panama':'PANAMÁ',
    'paraguay':'PARAGUAY',
    'peru':'PERÚ',
    'sanCristobal':'SAN CRISTÓBAL Y NIEVES',
    'santaLucia':'SANTA LUCÍA',
    'sanVicente':'SAN VICENTE Y LAS GRANADINAS',
    'suriman':'SURINAM',
    'trinidadYtobago':'TRINIDAD Y TOBAGO',
    'uruguay':'URUGUAY',
    'venezuela':'VENEZUELA',

    /* tabla detalle */
    'policyAndStrategy' : 'Política y Estrategia de Seguridad Cibernética',
    'nationalCybersecurityStrategy' : 'Estrategia Nacional de Seguridad Cibernética',
    'strategyDevelopment' : 'Desarrollo de la Estrategia',
    'organization' : 'Organización',
    'content' : 'Contenido',
    'incidentResponse' : 'Respuesta a Incidentes',
    'identificationOfIncidents' : 'Identificación de Incidentes',
    'coordination' : 'Coordinación',
    'modeOfOperation' : 'Modo de Operación',
    'criticalInfrastructureProtection' : 'Protección de la Infraestructura Crítica (IC)',
    'riskManagementAndResponse' : 'Gestión de Riesgos y Respuesta',
    'crisisManagement' : 'Manejo de Crisis',
    'crisisDefense' : 'Defensa Cibernética',
    'strategy' : 'Estrategia',
    'communicationsRedundancy' : ' Redundancia de Comunicaciones',

    'cyberCultureAndSociety' : 'Cultura Cibernética y Sociedad',
    'cyberSecurityMindSet' : 'Mentalidad de Seguridad Cibernética',
    'government' : 'Gobierno',
    'privateSector' : 'Sector Privado',
    'users' : 'Usuarios',
    'trustConfidenceOnInternet' : 'Confianza y Seguridad en Internet',
    'userTrustOnInternet' : 'Confianza y Seguridad en el Internet del Usuario',
    'userTrustEGovermmentOnServices' : 'Confianza del Usuario en los Servicios de Gobierno Electrónico',
    'userTrustEcommerceService' : 'Confianza del Usuario en los Servicios de Comercio Electrónico',
    'userUnderstandingPersonalInformation' : 'Comprensión del Usuario de la Protección de la Información en Línea',
    'reportingMechanisms' : 'Mecanismos de Denuncia',
    'mediaAndSocialMedia' : 'Medios y Redes Sociales',

    'educationTraining' : 'Formación, Capacitación y Habilidades de Seguridad Cibernética',
    'awarenessRaising' : 'Sensibilización',
    'awarenessRaisingProgrammes' : 'Programas de Sensibilización',
    'executiveAwarenessRaising' : 'Sensibilización Ejecutiva',
    'frameworkForEducation' : 'Marco para la Formación',
    'provision' : 'Provisión',
    'administration' : 'Administración',
    'frameworkForProfessionalTraining' : 'Marco para la capacitación profesional',
    'uptake' : 'Apropiación',

    'legalAndRegulatoryFrameworks' : 'Marcos Legales y Regulatorios',
    'legalFrameworks' : 'Marcos Legales',
    'legislativeFrameworks' : 'Marcos legislativos para la seguridad de las TIC',
    'humanRightsOnline' : 'Privacidad, libertad de expresión y otros derechos humanos en línea',
    'dataProtectionLegislation' : 'Legislación sobre protección de datos',
    'childProtectionOnline' : 'Protección infantil en línea',
    'consumerProtectionLegislation' : 'Legislación de Protección al Consumidor',
    'intellectualPropertyLegislation' : 'Legislación de Propiedad Intelectual',
    'substantiveCybercrimeLegislation' : 'Legislación sustantiva contra el delito cibernético',
    'proceduralCybercrimeLegislation' : 'Legislación procesal contra el delito cibernético',
    'criminalJusticeSystem' : 'Sistema de justicia penal',
    'lawEnforcement' : 'Fuerzas del orden',
    'prosecution' : 'Enjuiciamiento',
    'courts' : 'Tribunales',
    'formalAndInformalCombatCybercrime' : 'Marcos de cooperación formales e informarles para combatir el delito cibernético',
    'formalCooperation' : 'Cooperación Formal',
    'informalCooperation' : 'Cooperación Informal',

    'technologies' : 'Estándares, organizaciones y tecnologías',
    'adherenceToStandards' : 'Cumplimiento de los estándares',
    'securityStandardsICT' : 'Estándares de seguridad de las TIC',
    'standardsProcurement' : 'Estándares en adquisiciones',
    'standardsSoftwareDevelopment' : 'Estándares en el desarrollo de software',
    'internetInfrastructureResilience' : 'Resiliencia de la infraestructura de Internet',
    'softwareQuality' : 'Calidad del software',
    'technicalSecurityControls' : 'Controles técnicos de seguridad',
    'cryptographicControls' : 'Controles criptográficos',
    'cyberSecurityMarketplace' : 'Mercado de Seguridad Cibernética',
    'cyberSecurityTechnologies' : 'Tecnologías de Seguridad Cibernética',
    'cybercrimeInsurance' : 'Seguro Cibernético',
    'responsibleDisclosure' : 'Divulgación Responsable',




};
