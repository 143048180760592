import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { CountryInfoService, CountryInfo } from './countryInfo.service'

declare var $:any;

@Injectable()
export class AttributeService {

  constructor(public http: HttpClient, public _countryInfoService: CountryInfoService) {}

  subAttributesToCompare:string[]=[];
  attributesToCompare:string[]=[];

  cleanSubAttributesToCompare(){
    this.attributesToCompare=[];
    this.subAttributesToCompare=[];
    this.attributes.forEach( attribute => {
      attribute.selected = false
      attribute.subAttributes.forEach (subAttribute => subAttribute.selected = false)
    })
  }

  thereAreSubAttributesToCompare(){
    return this.subAttributesToCompare.length > 0 
  }

  isSubAttributeSeleted(subAttribute:string){
    return this.subAttributesToCompare.filter(_=> _ == subAttribute).length > 0 
  }

  isAttributeSeleted(attribute:string){
    return this.attributesToCompare.filter(_=> _ == attribute).length > 0 
  }

  deleteSubAttributeToCompare(subAttribute:string){
    this.subAttributesToCompare = this.subAttributesToCompare.filter(_=> _ != subAttribute)
  }

  deleteAttributeToCompare(attribute:string){
    this.attributesToCompare = this.attributesToCompare.filter(_=> _ != attribute)
    this.attributes.find(_=> _.name == attribute).subAttributes.forEach( subAttribute => {
      this.deleteSubAttributeToCompare(subAttribute.name)
    })
  }

  deleteSubAttributeToCompareByAttribute(attribute:string){
    this.attributes.find(_=> _.name == attribute).subAttributes.forEach( subA => {
      subA.selected = false;
      this.deleteSubAttributeToCompare(subA.name);
    })
  }

  addSubAttributeToCompareByAttribute(attribute:string){
    this.attributes.find(_=> _.name == attribute).subAttributes.forEach( subA => {
      subA.selected = true;
      this.subAttributesToCompare.push(subA.name);
    })
  }

  policyAndStrategies : Attribute= {
    'name':'policyAndStrategy',
    'selected': false,
    'image':'t_politicas',
    'design':'green',
    'subAttributes': [
      {'name': 'nationalCybersecurityStrategy',  'selected': false, "details": ["strategyDevelopment","organization","content"]},
      {'name': 'incidentResponse', 'selected': false, "details": ["identificationOfIncidents","organization","coordination","modeOfOperation"]},
      {'name': 'criticalInfrastructureProtection', 'selected': false, "details": ["identificationOfIncidents","organization","riskManagementAndResponse"]},
      {'name': 'crisisManagement', 'selected': false, "details": ["crisisManagement"]},
      {'name': 'crisisDefense', 'selected': false, "details": ["strategy","organization","coordination"]},
      {'name': 'communicationsRedundancy', 'selected': false, "details": ["communicationsRedundancy"]}
    ]
  }

  cultureAndSociety : Attribute= {
    'name':'cyberCultureAndSociety',
    'selected': false,
    'image':'t_cultura',
    'design':'blue',
    'subAttributes': [
      {'name': 'cyberSecurityMindSet', 'selected': false, "details": ["government","publicSector","users"]},
      {'name': 'trustConfidenceOnInternet', 'selected': false, "details": ["userTrustOnInternet","userTrustEGovermmentOnServices","userTrustEcommerceService"]},
      {'name': 'userUnderstandingPersonalInformation', 'selected': false, "details": ["userUnderstandingPersonalInformation"]},
      {'name': 'reportingMechanisms', 'selected': false, "details": ["reportingMechanisms"]},
      {'name': 'mediaAndSocialMedia', 'selected': false, "details": ["mediaAndSocialMedia"]},
    ]
  }

  education : Attribute= {
    'name':'educationTraining',
    'selected': false,
    'image':'t_educacion',
    'design':'orange',
    'subAttributes': [
      {'name': 'awarenessRaising', 'selected': false, "details": ["awarenessRaisingProgrammes","executiveAwarenessRaising"]},
      {'name': 'frameworkForEducation', 'selected': false, "details": ["provision","administration"]},
      {'name': 'frameworkForProfessionalTraining', 'selected': false, "details": ["provision","uptake"]}
    ]
  }

  legalFramework : Attribute= {
    'name':'legalAndRegulatoryFrameworks',
    'selected': false,
    'image':'t_marcos',
    'design':'red',
    'subAttributes': [
      {'name': 'legalFrameworks', 'selected': false, "details": [
        "legislativeFrameworks",
        "humanRightsOnline",
        "dataProtectionLegislation",
        "childProtectionOnline",
        "consumerProtectionLegislation",
        "intellectualPropertyLegislation",
        "substantiveCybercrimeLegislation",
        "proceduralCybercrimeLegislation"]},
      {'name': 'criminalJusticeSystem', 'selected': false, "details": ["lawEnforcement","prosecution","courts"]},
      {'name': 'formalAndInformalCombatCybercrime', 'selected': false, "details": ["formalCooperation","informalCooperation"]}
    ]
  }
   
  technology : Attribute= {
    'name':'technologies',
    'selected': false,
    'image':'t_tecnologia',
    'design':'yellow',
    'subAttributes': [
      {'name': 'adherenceToStandards',  'selected': false, "details": ["securityStandardsICT","standardsProcurement","standardsSoftwareDevelopment"]},
      {'name': 'internetInfrastructureResilience',  'selected': false, "details": ["internetInfrastructureResilience"]},
      {'name': 'softwareQuality',  'selected': false, "details": ["softwareQuality"]},
      {'name': 'technicalSecurityControls',  'selected': false, "details": ["technicalSecurityControls"]},
      {'name': 'cryptographicControls',  'selected': false, "details": ["cryptographicControls"]},
      {'name': 'cyberSecurityMarketplace',  'selected': false, "details": ["cyberSecurityTechnologies","cybercrimeInsurance"]},
      {'name': 'responsibleDisclosure',  'selected': false, "details": ["responsibleDisclosure"]}
    ]
  }
  
  attributes:Attribute[]= [
    this.policyAndStrategies,
    this.cultureAndSociety,
    this.education,
    this.legalFramework,
    this.technology
  ];

}

export interface Attribute {
  name: string;
  selected: Boolean,
  image: string,
  design: string,
  subAttributes: SubAttribute[];
}

interface SubAttribute{
  name: string,
  selected: Boolean,
  details:string[]
}
