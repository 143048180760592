import { Component, OnInit, NgZone, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { latLng, LatLng, tileLayer } from 'leaflet';
import { circle, geoJSON, icon, Layer, marker, polygon } from 'leaflet';
import * as L from 'leaflet';
import { CountryService, Country } from '../../../services/country.service'
import { StatusComponentsService } from '../../../services/statusComponents.service'

import argentina from '../../../../assets/geo-maps/00_argentina.json';  
import antigua_and_barbuda from '../../../../assets/geo-maps/01_antigua_and_barbuda.json';
import bahamas from '../../../../assets/geo-maps/02_bahamas.json';
import barbados from '../../../../assets/geo-maps/03_barbados.json';
import belize from '../../../../assets/geo-maps/04_belize.json';
import bolivia from '../../../../assets/geo-maps/05_bolivia.json';
import brazil from '../../../../assets/geo-maps/06_brazil.json';
import chile from '../../../../assets/geo-maps/07_chile.json';
import colombia from '../../../../assets/geo-maps/08_colombia.json';
import costa_rica from '../../../../assets/geo-maps/09_costa_rica.json';
import dominica from '../../../../assets/geo-maps/10_dominica.json';
import ecuador from '../../../../assets/geo-maps/11_ecuador.json';
import el_salvador from '../../../../assets/geo-maps/12_el_salvador.json';
import grenada from '../../../../assets/geo-maps/13_grenada.json';
import guatemala from '../../../../assets/geo-maps/14_guatemala.json';
import guyana from '../../../../assets/geo-maps/15_guyana.json';
import haiti from '../../../../assets/geo-maps/16_haiti.json';
import honduras from '../../../../assets/geo-maps/17_honduras.json';
import jamaica from '../../../../assets/geo-maps/18_jamaica.json';
import mexico from '../../../../assets/geo-maps/19_mexico.json';
import nicaragua from '../../../../assets/geo-maps/20_nicaragua.json';
import panama from '../../../../assets/geo-maps/21_panama.json';
import paraguay from '../../../../assets/geo-maps/22_paraguay.json';
import peru from '../../../../assets/geo-maps/23_peru.json';
import dominican_republic from '../../../../assets/geo-maps/24_dominican_republic.json';
import saint_kitts from '../../../../assets/geo-maps/25_saint_kitts_and_nevis.json';
import saint_vincent from '../../../../assets/geo-maps/26_saint_vincent_and_the_grenadines.json';
import saint_lucia from '../../../../assets/geo-maps/27_saint_lucia.json';
import suriname from '../../../../assets/geo-maps/28_suriname.json';
import trinidad_and_tobago from '../../../../assets/geo-maps/29_trinidad_and_tobago.json';
import uruguay from '../../../../assets/geo-maps/30_uruguay.json';
import venezuela from '../../../../assets/geo-maps/31_venezuela.json';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html'
})
export class MapaComponent implements OnInit {

  @Input() countriesSelected: string[];
  @Output() countriesSelectedToSendToCheckBox = new EventEmitter<string>();

  layers = [];
  countrySelected:string[]=[];
  isClickInCheckBox:boolean=false

  constructor(public zone: NgZone,
    public _countryService: CountryService,
    public _statusComponentsService : StatusComponentsService) { }

  ngOnInit() {
    // Argentina
    this.argentina.bindTooltip('Argentina')
    this.argentina.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('AR');  
    });
    this.argentina.on('selectCountry', ()=> {
      this.zone.run(() => {
        if(!this.isCountrySelected('Argentina')){
          this.argentina.setStyle(this.styleClicked)
          this.countrySelected.push('Argentina');
        } else {
          this.argentina.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Argentina'), 1);
        }
      });
    })
    this.argentina.on('mouseover', ()=> {
      this.argentina.setStyle(this.styleMouseOver)
    })
    this.argentina.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Argentina')){
        this.argentina.setStyle(this.styleClicked)
      } else {
        this.argentina.setStyle(this.styleDefault)
      }
    })

    // Antigua and Barbuda
    this.antigua_and_barbuda.bindTooltip('Antigua y Barbuda')
    this.antigua_and_barbuda.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('AG');  
    });
    this.antigua_and_barbuda.on('selectCountry', ()=> {
      this.zone.run(() => {
        if(!this.isCountrySelected('Antigua y Barbuda')){
          this.antigua_and_barbuda.setStyle(this.styleClicked)
          this.countrySelected.push('Antigua y Barbuda');
        } else {
          this.antigua_and_barbuda.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Antigua y Barbuda'), 1);
        }
      });
    })
    this.antigua_and_barbuda.on('mouseover', ()=> {
      this.antigua_and_barbuda.setStyle(this.styleMouseOver)
    })
    this.antigua_and_barbuda.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Antigua y Barbuda')){
        this.antigua_and_barbuda.setStyle(this.styleClicked)
      } else {
        this.antigua_and_barbuda.setStyle(this.styleDefault)
      }
    })

    // Bahamas
    this.bahamas.bindTooltip('Bahamas')
    this.bahamas.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('BS');  
    });
    this.bahamas.on('selectCountry', ()=> {
      this.zone.run(() => {
        if(!this.isCountrySelected('Bahamas')){
          this.bahamas.setStyle(this.styleClicked)
          this.countrySelected.push('Bahamas');
        } else {
          this.bahamas.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Bahamas'), 1);
        }
      });
    })
    this.bahamas.on('mouseover', ()=> {
      this.bahamas.setStyle(this.styleMouseOver)
    })
    this.bahamas.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Bahamas')){
        this.bahamas.setStyle(this.styleClicked)
      } else {
        this.bahamas.setStyle(this.styleDefault)
      }
    })

    // Barbados
    this.barbados.bindTooltip('Barbados')
    this.barbados.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('BB'); 
    });
    this.barbados.on('selectCountry', ()=> {
      this.zone.run(() => {
        if(!this.isCountrySelected('Barbados')){
          this.barbados.setStyle(this.styleClicked)
          this.countrySelected.push('Barbados');
        } else {
          this.barbados.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Barbados'), 1);
        }
      });
    })
    this.barbados.on('mouseover', ()=> {
      this.barbados.setStyle(this.styleMouseOver)
    })
    this.barbados.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Barbados')){
        this.barbados.setStyle(this.styleClicked)
      } else {
        this.barbados.setStyle(this.styleDefault)
      }
    })

    // Belize
    this.belize.bindTooltip('Belize')
    this.belize.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('BZ'); 
    });
    this.belize.on('selectCountry', ()=> {
      this.zone.run(() => {
        if(!this.isCountrySelected('Belize')){
          this.belize.setStyle(this.styleClicked)
          this.countrySelected.push('Belize');
        } else {
          this.belize.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Belize'), 1);
        }
      });
    })
    this.belize.on('mouseover', ()=> {
      this.belize.setStyle(this.styleMouseOver)
    })
    this.belize.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Belize')){
        this.belize.setStyle(this.styleClicked)
      } else {
        this.belize.setStyle(this.styleDefault)
      }
    })

    // Bolivia
    this.bolivia.bindTooltip('Bolivia')
    this.bolivia.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('BO'); 
    });
    this.bolivia.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Bolivia')){
          this.bolivia.setStyle(this.styleClicked)
          this.countrySelected.push('Bolivia');
        } else {
          this.bolivia.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Bolivia'), 1);
        }
      }); 
    })
    this.bolivia.on('mouseover', ()=> {
      this.bolivia.setStyle(this.styleMouseOver)
    })
    this.bolivia.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Bolivia')){
        this.bolivia.setStyle(this.styleClicked)
      } else {
        this.bolivia.setStyle(this.styleDefault)
      }
    })

    // Brazil
    this.brazil.bindTooltip('Brasil')
    this.brazil.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('BR'); 
    });
    this.brazil.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Brasil')){
          this.brazil.setStyle(this.styleClicked)
          this.countrySelected.push('Brasil');
        } else {
          this.brazil.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Brasil'), 1);
        }
      });
    })
    this.brazil.on('mouseover', ()=> {
      this.brazil.setStyle(this.styleMouseOver)
    })
    this.brazil.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Brasil')){
        this.brazil.setStyle(this.styleClicked)
      } else {
        this.brazil.setStyle(this.styleDefault)
      }
    })

    // Chile
    this.chile.bindTooltip('Chile')
    this.chile.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('CL');
    });
    this.chile.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Chile')){
          this.chile.setStyle(this.styleClicked)
          this.countrySelected.push('Chile');
        } else {
          this.chile.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Chile'), 1);
        }
      });
    });
    this.chile.on('mouseover', ()=> {
      this.chile.setStyle(this.styleMouseOver)
    })
    this.chile.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Chile')){
        this.chile.setStyle(this.styleClicked)
      } else {
        this.chile.setStyle(this.styleDefault)
      }
    })

    // Colombia
    this.colombia.bindTooltip('Colombia')
    this.colombia.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('CO');  
    });
    this.colombia.on('selectCountry', ()=> {
      this.zone.run(() => {
        if(!this.isCountrySelected('Colombia')){
          this.colombia.setStyle(this.styleClicked)
          this.countrySelected.push('Colombia');
        } else {
          this.colombia.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Colombia'), 1);
        }  
      });
    })
    this.colombia.on('mouseover', ()=> {
      this.colombia.setStyle(this.styleMouseOver)
    })
    this.colombia.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Colombia')){
        this.colombia.setStyle(this.styleClicked)
      } else {
        this.colombia.setStyle(this.styleDefault)
      }
    })

    // Costa Rica
    this.costa_rica.bindTooltip('Costa Rica')
    this.costa_rica.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('CR');  
    });
    this.costa_rica.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Costa Rica')){
          this.costa_rica.setStyle(this.styleClicked)
          this.countrySelected.push('Costa Rica');
        } else {
          this.costa_rica.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Costa Rica'), 1);
        }
      });     
    });
    this.costa_rica.on('mouseover', ()=> {
      this.costa_rica.setStyle(this.styleMouseOver)
    })
    this.costa_rica.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Costa Rica')){
        this.costa_rica.setStyle(this.styleClicked)
      } else {
        this.costa_rica.setStyle(this.styleDefault)
      }
    })

    // Dominica
    this.dominica.bindTooltip('Dominica')
    this.dominica.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('DM'); 
    });
    this.dominica.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Dominica')){
          this.dominica.setStyle(this.styleClicked)
          this.countrySelected.push('Dominica');
        } else {
          this.dominica.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Dominica'), 1);
        }
      });
    });
    this.dominica.on('mouseover', ()=> {
      this.dominica.setStyle(this.styleMouseOver)
    })
    this.dominica.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Dominica')){
        this.dominica.setStyle(this.styleClicked)
      } else {
        this.dominica.setStyle(this.styleDefault)
      }
    })

    // Ecuador
    this.ecuador.bindTooltip('Ecuador')
    this.ecuador.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('EC'); 
    });
    this.ecuador.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Ecuador')){
          this.ecuador.setStyle(this.styleClicked)
          this.countrySelected.push('Ecuador');
        } else {
          this.ecuador.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Ecuador'), 1);
        }
      });
    });
    this.ecuador.on('mouseover', ()=> {
      this.ecuador.setStyle(this.styleMouseOver)
    })
    this.ecuador.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Ecuador')){
        this.ecuador.setStyle(this.styleClicked)
      } else {
        this.ecuador.setStyle(this.styleDefault)
      }
    })

    // El salvador
    this.el_salvador.bindTooltip('El salvador')
    this.el_salvador.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('SV');
    });
    this.el_salvador.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('El salvador')){
          this.el_salvador.setStyle(this.styleClicked)
          this.countrySelected.push('El salvador');
        } else {
          this.el_salvador.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('El salvador'), 1);
        }
      });
    });
    this.el_salvador.on('mouseover', ()=> {
      this.el_salvador.setStyle(this.styleMouseOver)
    })
    this.el_salvador.on('mouseout', (feature)=> {
      if(this.isCountrySelected('El salvador')){
        this.el_salvador.setStyle(this.styleClicked)
      } else {
        this.el_salvador.setStyle(this.styleDefault)
      }
    })

    // Grenada
    this.grenada.bindTooltip('Grenada')
    this.grenada.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('GD');
    });
    this.grenada.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Grenada')){
          this.grenada.setStyle(this.styleClicked)
          this.countrySelected.push('Grenada');
        } else {
          this.grenada.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Grenada'), 1);
        }
      });
    });
    this.grenada.on('mouseover', ()=> {
      this.grenada.setStyle(this.styleMouseOver)
    })
    this.grenada.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Grenada')){
        this.grenada.setStyle(this.styleClicked)
      } else {
        this.grenada.setStyle(this.styleDefault)
      }
    })

    // Guatemala
    this.guatemala.bindTooltip('Guatemala')
    this.guatemala.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('GT');
    });
    this.guatemala.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Guatemala')){
          this.guatemala.setStyle(this.styleClicked)
          this.countrySelected.push('Guatemala');
        } else {
          this.guatemala.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Guatemala'), 1);
        }
      });
    });
    this.guatemala.on('mouseover', ()=> {
      this.guatemala.setStyle(this.styleMouseOver)
    })
    this.guatemala.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Guatemala')){
        this.guatemala.setStyle(this.styleClicked)
      } else {
        this.guatemala.setStyle(this.styleDefault)
      }
    })

    // Guyana
    this.guyana.bindTooltip('Guyana')
    this.guyana.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('GY');
    });
    this.guyana.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Guyana')){
          this.guyana.setStyle(this.styleClicked)
          this.countrySelected.push('Guyana');
        } else {
          this.guyana.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Guyana'), 1);
        }
      });
    });
    this.guyana.on('mouseover', ()=> {
      this.guyana.setStyle(this.styleMouseOver)
    })
    this.guyana.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Guyana')){
        this.guyana.setStyle(this.styleClicked)
      } else {
        this.guyana.setStyle(this.styleDefault)
      }
    })

    // Haiti
    this.haiti.bindTooltip('Haiti')
    this.haiti.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('HT');
    });
    this.haiti.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Haiti')){
          this.haiti.setStyle(this.styleClicked)
          this.countrySelected.push('Haiti');
        } else {
          this.haiti.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Haiti'), 1);
        }
      }); 
    });
    this.haiti.on('mouseover', ()=> {
      this.haiti.setStyle(this.styleMouseOver)
    })
    this.haiti.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Haiti')){
        this.haiti.setStyle(this.styleClicked)
      } else {
        this.haiti.setStyle(this.styleDefault)
      }
    })

    // Honduras
    this.honduras.bindTooltip('Honduras')
    this.honduras.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('HN');
    });
    this.honduras.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Honduras')){
          this.honduras.setStyle(this.styleClicked)
          this.countrySelected.push('Honduras');
        } else {
          this.honduras.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Honduras'), 1);
        }
      });
    });
    this.honduras.on('mouseover', ()=> {
      this.honduras.setStyle(this.styleMouseOver)
    })
    this.honduras.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Haiti')){
        this.honduras.setStyle(this.styleClicked)
      } else {
        this.honduras.setStyle(this.styleDefault)
      }
    })

    // Jamaica
    this.jamaica.bindTooltip('Jamaica')
    this.jamaica.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('JM');
    });
    this.jamaica.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Jamaica')){
          this.jamaica.setStyle(this.styleClicked)
          this.countrySelected.push('Jamaica');
        } else {
          this.jamaica.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Jamaica'), 1);
        }
      });
    });
    this.jamaica.on('mouseover', ()=> {
      this.jamaica.setStyle(this.styleMouseOver)
    })
    this.jamaica.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Haiti')){
        this.jamaica.setStyle(this.styleClicked)
      } else {
        this.jamaica.setStyle(this.styleDefault)
      }
    })

    // Mexico
    this.mexico.bindTooltip('Mexico')
    this.mexico.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('MX');
    });
    this.mexico.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Mexico')){
          this.mexico.setStyle(this.styleClicked)
          this.countrySelected.push('Mexico');
        } else {
          this.mexico.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Mexico'), 1);
        }
      });
    });
    this.mexico.on('mouseover', ()=> {
      this.mexico.setStyle(this.styleMouseOver)
    })
    this.mexico.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Mexico')){
        this.mexico.setStyle(this.styleClicked)
      } else {
        this.mexico.setStyle(this.styleDefault)
      }
    })

    // Nicaragua
    this.nicaragua.bindTooltip('Nicaragua')
    this.nicaragua.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('NI');
    });
    this.nicaragua.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Nicaragua')){
          this.nicaragua.setStyle(this.styleClicked)
          this.countrySelected.push('Nicaragua');
        } else {
          this.nicaragua.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Nicaragua'), 1);
        }
      });
    });
    this.nicaragua.on('mouseover', ()=> {
      this.nicaragua.setStyle(this.styleMouseOver)
    })
    this.nicaragua.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Haiti')){
        this.nicaragua.setStyle(this.styleClicked)
      } else {
        this.nicaragua.setStyle(this.styleDefault)
      }
    })

    // Panama
    this.panama.bindTooltip('Panama')
    this.panama.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('PA');
    });
    this.panama.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Panama')){
          this.panama.setStyle(this.styleClicked)
          this.countrySelected.push('Panama');
        } else {
          this.panama.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Panama'), 1);
        }
      });
    });
    this.panama.on('mouseover', ()=> {
      this.panama.setStyle(this.styleMouseOver)
    })
    this.panama.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Panama')){
        this.panama.setStyle(this.styleClicked)
      } else {
        this.panama.setStyle(this.styleDefault)
      }
    })

    // Paraguay
    this.paraguay.bindTooltip('Paraguay')
    this.paraguay.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('PY');
    });
    this.paraguay.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Paraguay')){
          this.paraguay.setStyle(this.styleClicked)
          this.countrySelected.push('Paraguay');
        } else {
          this.paraguay.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Paraguay'), 1);
        }
      });
    });
    this.paraguay.on('mouseover', ()=> {
      this.paraguay.setStyle(this.styleMouseOver)
    })
    this.paraguay.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Paraguay')){
        this.paraguay.setStyle(this.styleClicked)
      } else {
        this.paraguay.setStyle(this.styleDefault)
      }
    })

    // Peru
    this.peru.bindTooltip('Peru')
    this.peru.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('PE');
    });
    this.peru.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Peru')){
          this.peru.setStyle(this.styleClicked)
          this.countrySelected.push('Peru');
        } else {
          this.peru.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Peru'), 1);
        }
      }); 
    });
    this.peru.on('mouseover', ()=> {
      this.peru.setStyle(this.styleMouseOver)
    })
    this.peru.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Peru')){
        this.peru.setStyle(this.styleClicked)
      } else {
        this.peru.setStyle(this.styleDefault)
      }
    })

    // Republica Dominicana
    this.dominican_republic.bindTooltip('Republica Dominicana')
    this.dominican_republic.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('DO');
    });
    this.dominican_republic.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Republica Dominicana')){
          this.dominican_republic.setStyle(this.styleClicked)
          this.countrySelected.push('Republica Dominicana');
        } else {
          this.dominican_republic.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Republica Dominicana'), 1);
        }
      });
    });
    this.dominican_republic.on('mouseover', ()=> {
      this.dominican_republic.setStyle(this.styleMouseOver)
    })
    this.dominican_republic.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Republica Dominicana')){
        this.dominican_republic.setStyle(this.styleClicked)
      } else {
        this.dominican_republic.setStyle(this.styleDefault)
      }
    })

    // Saint Kitts
    this.saint_kitts.bindTooltip('Saint Kitts and Nevis')
    this.saint_kitts.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('KN');
    });
    this.saint_kitts.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Saint Kitts and Nevis')){
          this.saint_kitts.setStyle(this.styleClicked)
          this.countrySelected.push('Saint Kitts and Nevis');
        } else {
          this.saint_kitts.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Saint Kitts and Nevis'), 1);
        }
      });
    });
    this.saint_kitts.on('mouseover', ()=> {
      this.saint_kitts.setStyle(this.styleMouseOver)
    })
    this.saint_kitts.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Saint Kitts and Nevis')){
        this.saint_kitts.setStyle(this.styleClicked)
      } else {
        this.saint_kitts.setStyle(this.styleDefault)
      }
    })

    // Saint Vicent and The Grenadines
    this.saint_vincent.bindTooltip('Saint Vicent and The Grenadines')
    this.saint_vincent.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('VC');
    });
    this.saint_vincent.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Saint Vicent and The Grenadines')){
          this.saint_vincent.setStyle(this.styleClicked)
          this.countrySelected.push('Saint Vicent and The Grenadines');
        } else {
          this.saint_vincent.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Saint Vicent and The Grenadines'), 1);
        }
      });
    });
    this.saint_vincent.on('mouseover', ()=> {
      this.saint_vincent.setStyle(this.styleMouseOver)
    })
    this.saint_vincent.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Saint Vicent and The Grenadines')){
        this.saint_vincent.setStyle(this.styleClicked)
      } else {
        this.saint_vincent.setStyle(this.styleDefault)
      }
    })

    // Saint Lucia
    this.saint_lucia.bindTooltip('Saint Lucia')
    this.saint_lucia.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('LC');
    });
    this.saint_lucia.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Saint Lucia')){
          this.saint_lucia.setStyle(this.styleClicked)
          this.countrySelected.push('Saint Lucia');
        } else {
          this.saint_lucia.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Saint Lucia'), 1);
        }
      });
    });
    this.saint_lucia.on('mouseover', ()=> {
      this.saint_lucia.setStyle(this.styleMouseOver)
    })
    this.saint_lucia.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Saint Lucia')){
        this.saint_lucia.setStyle(this.styleClicked)
      } else {
        this.saint_lucia.setStyle(this.styleDefault)
      }
    })

    // Suriname
    this.suriname.bindTooltip('Suriname')
    this.suriname.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('SR');
    });
    this.suriname.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Suriname')){
          this.suriname.setStyle(this.styleClicked)
          this.countrySelected.push('Suriname');
        } else {
          this.suriname.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Suriname'), 1);
        }
      });
    });
    this.suriname.on('mouseover', ()=> {
      this.suriname.setStyle(this.styleMouseOver)
    })
    this.suriname.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Suriname')){
        this.suriname.setStyle(this.styleClicked)
      } else {
        this.suriname.setStyle(this.styleDefault)
      }
    })

    // Trinidad y Tobago
    this.trinidad_and_tobago.bindTooltip('Trinidad y Tobago')
    this.trinidad_and_tobago.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('TT');
    });
    this.trinidad_and_tobago.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Trinidad y Tobago')){
          this.trinidad_and_tobago.setStyle(this.styleClicked)
          this.countrySelected.push('Trinidad y Tobago');
        } else {
          this.trinidad_and_tobago.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Trinidad y Tobago'), 1);
        }
      });
    });
    this.trinidad_and_tobago.on('mouseover', ()=> {
      this.trinidad_and_tobago.setStyle(this.styleMouseOver)
    })
    this.trinidad_and_tobago.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Trinidad y Tobago')){
        this.trinidad_and_tobago.setStyle(this.styleClicked)
      } else {
        this.trinidad_and_tobago.setStyle(this.styleDefault)
      }
    })

    // Uruguay
    this.uruguay.bindTooltip('Uruguay')
    this.uruguay.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('UY');
    });
    this.uruguay.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Uruguay')){
          this.uruguay.setStyle(this.styleClicked)
          this.countrySelected.push('Uruguay');
        } else {
          this.uruguay.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Uruguay'), 1);
        }
      });
    });
    this.uruguay.on('mouseover', ()=> {
      this.uruguay.setStyle(this.styleMouseOver)
    })
    this.uruguay.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Uruguay')){
        this.uruguay.setStyle(this.styleClicked)
      } else {
        this.uruguay.setStyle(this.styleDefault)
      }
    })

    // Venezuela
    this.venezuela.bindTooltip('Venezuela')
    this.venezuela.on('click', () => {
      this.countriesSelectedToSendToCheckBox.emit('VE');
    });
    this.venezuela.on('selectCountry', () => {
      this.zone.run(() => {
        if(!this.isCountrySelected('Venezuela')){
          this.venezuela.setStyle(this.styleClicked)
          this.countrySelected.push('Venezuela');
        } else {
          this.venezuela.setStyle(this.styleMouseOver)
          this.countrySelected.splice(this.countrySelected.indexOf('Venezuela'), 1);
        }
      });
    });
    this.venezuela.on('mouseover', ()=> {
      this.venezuela.setStyle(this.styleMouseOver)
    })
    this.venezuela.on('mouseout', (feature)=> {
      if(this.isCountrySelected('Venezuela')){
        this.venezuela.setStyle(this.styleClicked)
      } else {
        this.venezuela.setStyle(this.styleDefault)
      }
    })
    
  }

  ngAfterViewInit(){
    this._statusComponentsService.markMapaComponentLoaded();
    for (let countrySelectedIso of this._countryService.getCountriesSelectedISO()) this.paintCountryAfterClickCountryByCheckBox(countrySelectedIso)

  }

  ngOnDestroy(){
    this._statusComponentsService.markMapaComponentUnloaded();
  }

  styleDefault = {
    weight: 1,
    color: 'white',
    dashArray: '',
    fillOpacity: 0.9,
    fillColor: 'grey'
  }

  styleClicked = {
    weight: 1,
    color: '#fff',
    dashArray: '',
    fillOpacity: 1,
    fillColor: '#70A3FE'
  }

  styleMouseOver = {
    weight: 1,
    color: '#fff',
    dashArray: '',
    fillOpacity: 1,
    fillColor: '#004E70'
  }

  // GeoJSONS
  argentina = geoJSON((argentina) as any, { style: () => (this.styleDefault)})
  antigua_and_barbuda = geoJSON((antigua_and_barbuda) as any, { style: () => (this.styleDefault)})
  bahamas = geoJSON((bahamas) as any, { style: () => (this.styleDefault)})
  barbados = geoJSON((barbados) as any, { style: () => (this.styleDefault)})
  belize  = geoJSON((belize) as any, { style: () => (this.styleDefault)})
  bolivia = geoJSON((bolivia) as any, { style: () => (this.styleDefault)})
  brazil = geoJSON((brazil) as any, { style: () => (this.styleDefault)})
  chile = geoJSON((chile) as any, { style: () => (this.styleDefault)})
  colombia = geoJSON((colombia) as any, { style: () => (this.styleDefault)})
  costa_rica = geoJSON((costa_rica) as any, { style: () => (this.styleDefault)})
  dominica = geoJSON((dominica) as any, { style: () => (this.styleDefault)})
  ecuador = geoJSON((ecuador) as any, { style: () => (this.styleDefault)})
  el_salvador = geoJSON((el_salvador) as any, { style: () => (this.styleDefault)})
  grenada = geoJSON((grenada) as any, { style: () => (this.styleDefault)})
  guatemala = geoJSON((guatemala) as any, { style: () => (this.styleDefault)})
  guyana = geoJSON((guyana) as any, { style: () => (this.styleDefault)})
  haiti = geoJSON((haiti) as any, { style: () => (this.styleDefault)})
  honduras = geoJSON((honduras) as any, { style: () => (this.styleDefault)})
  jamaica = geoJSON((jamaica) as any, { style: () => (this.styleDefault)})
  mexico = geoJSON((mexico) as any, { style: () => (this.styleDefault)})
  nicaragua = geoJSON((nicaragua) as any, { style: () => (this.styleDefault)})
  panama = geoJSON((panama) as any, { style: () => (this.styleDefault)})
  paraguay = geoJSON((paraguay) as any, { style: () => (this.styleDefault)})
  peru = geoJSON((peru) as any, { style: () => (this.styleDefault)})
  dominican_republic = geoJSON((dominican_republic) as any, { style: () => (this.styleDefault)})
  saint_kitts = geoJSON((saint_kitts) as any, { style: () => (this.styleDefault)})
  saint_vincent = geoJSON((saint_vincent) as any, { style: () => (this.styleDefault)})
  saint_lucia = geoJSON((saint_lucia) as any, { style: () => (this.styleDefault)})
  suriname = geoJSON((suriname) as any, { style: () => (this.styleDefault)})
  trinidad_and_tobago = geoJSON((trinidad_and_tobago) as any, { style: () => (this.styleDefault)})
  uruguay = geoJSON((uruguay) as any, { style: () => (this.styleDefault)})
  venezuela = geoJSON((venezuela) as any, { style: () => (this.styleDefault)})

  options = {
    zoom: 2.2,
    center: latLng(-15.085666, -80.318073),
    minZoom: 2.2,
    maxBoundsViscosity: 1.0,
    maxBounds:  L.latLngBounds(L.latLng(-60, -220), L.latLng(45, 75)),
    doubleClickZoom: false,
    dragging: true,
    keyboard: false,
    closePopupOnClick: false,
    layers: [
      this.argentina,
      this.antigua_and_barbuda,
      this.bahamas,
      this.barbados,
      this.belize,
      this.bolivia,
      this.brazil,
      this.chile,
      this.colombia,
      this.costa_rica,
      this.dominica,
      this.ecuador,
      this.el_salvador,
      this.grenada,
      this.guatemala,
      this.guyana,
      this.haiti,
      this.honduras,
      this.jamaica,
      this.mexico,
      this.nicaragua,
      this.panama,
      this.paraguay,
      this.peru,
      this.dominican_republic,
      this.saint_kitts,
      this.saint_vincent,
      this.saint_lucia,
      this.suriname,
      this.trinidad_and_tobago,
      this.uruguay,
      this.venezuela
    ]
  };

  paintCountryAfterClickCountryByCheckBox(iso:string){
    this.isClickInCheckBox=true;
    switch (iso) {
      case "AG":
        this.antigua_and_barbuda.fire('selectCountry')
        this.antigua_and_barbuda.fire('mouseout')
        break; 
      case "AR":
        this.argentina.fire('selectCountry')
        this.argentina.fire('mouseout')
        break; 
      case "BS":
        this.bahamas.fire('selectCountry')
        this.bahamas.fire('mouseout')
        break; 
      case "BB":
        this.barbados.fire('selectCountry')
        this.barbados.fire('mouseout')
        break; 
      case "BZ":
        this.belize.fire('selectCountry')
        this.belize.fire('mouseout')
        break; 
      case "BO":
        this.bolivia.fire('selectCountry')
        this.bolivia.fire('mouseout')
        break; 
      case "BR":
        this.brazil.fire('selectCountry')
        this.brazil.fire('mouseout')
        break; 
      case "CL":
        this.chile.fire('selectCountry')
        this.chile.fire('mouseout')
        break; 
      case "CO":
        this.colombia.fire('selectCountry')
        this.colombia.fire('mouseout')
        break; 
      case "CR":
        this.costa_rica.fire('selectCountry')
        this.costa_rica.fire('mouseout')
        break;   
      case "DM":
        this.dominica.fire('selectCountry')
        this.dominica.fire('mouseout')
        break;  
      case "EC":
        this.ecuador.fire('selectCountry')
        this.ecuador.fire('mouseout')
        break;    
      case "SV":
        this.el_salvador.fire('selectCountry')
        this.el_salvador.fire('mouseout') 
        break;   
      case "GD":
        this.grenada.fire('selectCountry')
        this.grenada.fire('mouseout')
        break;       
      case "GT":
        this.guatemala.fire('selectCountry')
        this.guatemala.fire('mouseout')
        break;    
      case "GY":
        this.guyana.fire('selectCountry')
        this.guyana.fire('mouseout') 
        break;   
      case "HT":
        this.haiti.fire('selectCountry')
        this.haiti.fire('mouseout') 
        break; 
      case "HN":
        this.honduras.fire('selectCountry')
        this.honduras.fire('mouseout')  
        break;        
      case "JM":
        this.jamaica.fire('selectCountry')
        this.jamaica.fire('mouseout') 
        break;   
      case "MX":
        this.mexico.fire('selectCountry')
        this.mexico.fire('mouseout')   
        break; 
      case "NI":
        this.nicaragua.fire('selectCountry')
        this.nicaragua.fire('mouseout')  
        break;  
      case "PA":
        this.panama.fire('selectCountry')
        this.panama.fire('mouseout')  
        break; 
      case "PY":
        this.paraguay.fire('selectCountry')
        this.paraguay.fire('mouseout')  
        break; 
      case "PE":
        this.peru.fire('selectCountry')
        this.peru.fire('mouseout') 
        break; 
      case "DO":
        this.dominican_republic.fire('selectCountry')
        this.dominican_republic.fire('mouseout') 
        break; 
      case "KN":
        this.saint_kitts.fire('selectCountry')
        this.saint_kitts.fire('mouseout')    
        break;       
      case "VC":
        this.saint_vincent.fire('selectCountry')
        this.saint_vincent.fire('mouseout') 
        break; 
      case "LC":
        this.saint_lucia.fire('selectCountry')
        this.saint_lucia.fire('mouseout') 
        break; 
      case "SR":
        this.suriname.fire('selectCountry')
        this.suriname.fire('mouseout') 
        break; 
      case "TT":
        this.trinidad_and_tobago.fire('selectCountry')
        this.trinidad_and_tobago.fire('mouseout') 
        break; 
      case "UY":
        this.uruguay.fire('selectCountry')
        this.uruguay.fire('mouseout') 
        break; 
      case "VE":
        this.venezuela.fire('selectCountry')
        this.venezuela.fire('mouseout')   
        break; 
      default:
        console.error("Error en mapa-component, código iso alpha 2: " + iso + " no configurado");
    }
  }

  isCountrySelected(countryName:string):boolean{
    if (this.countrySelected.indexOf(countryName)!== -1){
      return true;
    }
    return false;
  }

  emitEvent(countryName:string){  
    if(!this.isClickInCheckBox){
      this.countriesSelectedToSendToCheckBox.emit(countryName);
    }
    this.isClickInCheckBox=false;
  }

  resetMap(){
    this.countrySelected = []
    this.argentina.fire('mouseout')
    this.antigua_and_barbuda.fire('mouseout')
    this.bahamas.fire('mouseout')
    this.barbados.fire('mouseout')
    this.belize.fire('mouseout')
    this.bolivia.fire('mouseout')
    this.brazil.fire('mouseout')
    this.chile.fire('mouseout')
    this.colombia.fire('mouseout')
    this.costa_rica.fire('mouseout')
    this.dominica.fire('mouseout')
    this.ecuador.fire('mouseout')
    this.el_salvador.fire('mouseout')
    this.grenada.fire('mouseout')
    this.guatemala.fire('mouseout')
    this.guyana.fire('mouseout')
    this.haiti.fire('mouseout')
    this.honduras.fire('mouseout')
    this.jamaica.fire('mouseout')
    this.mexico.fire('mouseout')
    this.nicaragua.fire('mouseout')
    this.panama.fire('mouseout')
    this.paraguay.fire('mouseout')
    this.peru.fire('mouseout')
    this.dominican_republic.fire('mouseout')
    this.saint_kitts.fire('mouseout')
    this.saint_vincent.fire('mouseout')
    this.saint_lucia.fire('mouseout')
    this.suriname.fire('mouseout')
    this.trinidad_and_tobago.fire('mouseout')
    this.uruguay.fire('mouseout')
    this.venezuela.fire('mouseout')
  }
}