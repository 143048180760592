import { Component, OnInit } from '@angular/core';
import { EmailService } from '../../services/email.service';
import { CaptchaService } from '../../services/captcha.service';
import { GLOBAL } from '../../services/global';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  public declarativeFormCaptchaValue: string;
  baseContact = { name: "", email: "", query: "", captcha: "" };
  contact = { ...this.baseContact }
  siteKey = "";
  constructor(public _emailService: EmailService) { }

  ngOnInit() {
    this.siteKey = GLOBAL.captchaSiteKey;
  }

  sendContactEmail() {
    this._emailService.sendContactEmail(this.contact).subscribe((result: any) => {
      this.contact = { ...this.baseContact };
      if (result.success) {
        $('#alert').fadeIn(1000);
        setTimeout(function () { $('#alert').fadeOut(1000); }, 2000);
      }

    }, (error) => {
      console.error(error);
      $('#alert-warning').fadeIn(1000);
      setTimeout(function () { $('#alert-warning').fadeOut(1000); }, 2000);
    }
    );
    grecaptcha.reset();
  }
}
