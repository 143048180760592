import { Component, OnInit } from '@angular/core';
import { TranslateService } from './services/translateService'
import { CaptchaService} from "./services/captcha.service";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public translatedText: string;
  public supportedLanguages: any[];

  constructor(private _translate: TranslateService, private service: CaptchaService) { }

  ngOnInit() {
    this.supportedLanguages = [
      { display: 'English', value: 'en' },
      { display: 'Español', value: 'es' }
    ];
    this.selectLang('es');
  }

  isCurrentLang(lang: string) {
    return lang === this._translate.currentLang;
  }

  selectLang(lang: string) {
    this._translate.use(lang);
    this.refreshText();
  }

  refreshText() {
    this.translatedText = this._translate.instant('hello world');
  }

  //function to resolve the reCaptcha and retrieve a token
  async resolved(captchaResponse: string, res) {
    console.log(`Resolved response token: ${captchaResponse}`);
    await this.sendTokenToBackend(captchaResponse); //declaring the token send function with a token parameter
  }

  //function to send the token to the node server
  sendTokenToBackend(tok) {
    //calling the service and passing the token to the service
    this.service.sendToken(tok).subscribe(
      data => {
        console.log(data)
      },
      err => {
        console.log(err)
      },
      () => { }
    );
  }

  title = 'app';

}
