import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { GLOBAL } from './global';
import { map } from 'rxjs/operators';

@Injectable()
export class CountryInfoService {
  url = `${GLOBAL.url}/countriesInfo`;
  allCountriesInfo:CountryInfo[]=[];
  countriesInfoSelected:CountryInfo[]=[];
  popupCountriesInfoSelected:CountryInfo[]=[];
  countryInfoSelected:CountryInfo= {
    "_id": "",
    "nameEs": "",
    "nameEn": "",
    "iso_alpha2": "",
    "totalPopulation": "",
    "peopleWithInternet": "",
    "peopleWithPhones": "",
    "internetPenetration": "",
    "infoEs": [],
    "infoEn": [],
  }

  constructor(public http: HttpClient) {}

  getAllCountriesInfo(){
    return this.http.get(this.url).pipe(
      map( (res: any) => {
      this.allCountriesInfo=res.countryInfoResult;
      return this.allCountriesInfo
    }));
  }

  addCountryInfoSelected(iso: string){
    var countriesSelected = this.allCountriesInfo.filter(c => c.iso_alpha2 == iso)
    if (countriesSelected.length > 0 && this.countriesInfoSelected.filter(_=>_.iso_alpha2==iso).length==0){
      this.countriesInfoSelected.push(countriesSelected[0]);
    }
    this.updateCountryInfoSelected()
  }

  updateCountryInfoSelected(){
    if(this.countryInfoSelected.iso_alpha2=="") {
      this.countryInfoSelected=this.countriesInfoSelected[0]
    }else{
      if(this.countriesInfoSelected.filter(_ => _.iso_alpha2 === this.countryInfoSelected.iso_alpha2).length==0){
        this.countryInfoSelected=this.countriesInfoSelected[0]
      }
    }
  }

  removeCountryInfoSelected(iso: string){
    this.countriesInfoSelected.filter(_ => _.iso_alpha2 === iso).forEach(c=> {
      this.countriesInfoSelected.splice(this.countriesInfoSelected.indexOf(c), 1);
    })
  }

}

export interface CountryInfo {
  _id: string;
  nameEs: string;
  nameEn: string;
  iso_alpha2: string;
  totalPopulation: string;
  peopleWithInternet: string;
  peopleWithPhones: string;
  internetPenetration: string;
  infoEs: string[];
  infoEn: string[];
}
