<div class="margin-top">
  <h3>{{ 'institutionalInfoTitulo' | translate }}</h3>
  <br>
  <div class="mr-3" *ngFor="let ins of institutionalinformation; let i=index">
    <p [hidden]="isCurrentLang('en')"><b>{{ins.nameEs}}</b></p>
    <p [hidden]="isCurrentLang('es')"><b>{{ins.nameEn}}</b></p>
    <pre class="text-justify" [hidden]="isCurrentLang('en')">{{ins.descriptionEs}} </pre>
    <pre class="text-justify" [hidden]="isCurrentLang('es')">{{ins.descriptionEn}} </pre>
    <br>
  </div>
</div>
