<div class="margin-top">
  <div class="row">
    <h3 >{{ 'ReportesFinales' | translate }} </h3>
  </div>
  <div class="row">
    <div class="col-12 col-md-12 col-lg-6  text-center mt-2"><!--2016-->
        <p><b>{{ 'ReporteFinal' | translate }} 2016</b></p>
        <img 
          src="assets/img/reporte-final/Reporte-2016-cover-en.png" 
          [hidden]="_translate._currentLang === 'es'"
          height="400" width="300"
        >
        <img 
          src="assets/img/reporte-final/Reporte-2016-cover-es.png" 
          [hidden]="_translate._currentLang === 'en'"
          height="400" width="300"
        ><br>
        <button class="btn btn-link" style="color: #6b737a" data-placement="right" alifn="center" [hidden]="_translate._currentLang === 'es'"
          onclick="window.open('https://publications.iadb.org/publications/english/document/Cybersecurity-Are-We-Ready-in-Latin-America-and-the-Caribbean.pdf')">
          <i class="fa fa-download fa-lg" aria-hidden="true"></i> {{'descargar' | translate}} 
        </button>
        <button class="btn btn-link" style="color: #6b737a" data-placement="right" alifn="center" [hidden]="_translate._currentLang === 'en'"
          onclick="window.open('https://publications.iadb.org/bitstream/handle/11319/7449/Ciberseguridad-Estamos-preparados-en-America-Latina-y-el-Caribe.pdf?')">
          <i class="fa fa-download fa-lg" aria-hidden="true"></i> {{'descargar' | translate}} 
        </button>
    </div>
    <div class="col-12  col-md-12 col-lg-6  text-center mt-2"><!--2020-->
      <p><b>{{ 'ReporteFinal' | translate }} 2020</b></p>
      <img 
        src="assets/img/reporte-final/Reporte-2020-cover-en.png" 
        [hidden]="_translate._currentLang === 'es'"
        height="400" width="300"
      >
      <img 
        src="assets/img/reporte-final/Reporte-2020-cover-es.png" 
        [hidden]="_translate._currentLang === 'en'"
        height="400" width="300"
      ><br>
      <button class="btn btn-link" style="color: #6b737a" data-placement="right" alifn="center" [hidden]="_translate._currentLang === 'es'"
        onclick="window.open('https://publications.iadb.org/publications/english/document/2020-Cybersecurity-Report-Risks-Progress-and-the-Way-Forward-in-Latin-America-and-the-Caribbean.pdf')">
        <i class="fa fa-download fa-lg" aria-hidden="true"></i> {{'descargar' | translate}} 
      </button>
      <button class="btn btn-link" style="color: #6b737a" data-placement="right" alifn="center" [hidden]="_translate._currentLang === 'en'"
        onclick="window.open('https://publications.iadb.org/publications/spanish/document/Reporte-Ciberseguridad-2020-riesgos-avances-y-el-camino-a-seguir-en-America-Latina-y-el-Caribe.pdf')">
        <i class="fa fa-download fa-lg" aria-hidden="true"></i> {{'descargar' | translate}} 
      </button>
      <br>
      <button class="btn btn-link" style="color: #6b737a" data-placement="right" alifn="center" [hidden]="_translate._currentLang === 'es'"
        onclick="window.open('https://publications.iadb.org/publications/portuguese/document/Relatorio-de-Ciberseguranca-2020-riscos-avancos-e-o-caminho-a-seguir-na-America-Latina-e-Caribe.pdf')">
        <i class="fa fa-download fa-lg" aria-hidden="true"></i> {{'descargarPortugues' | translate}} 
      </button>
      <button class="btn btn-link" style="color: #6b737a" data-placement="right" alifn="center" [hidden]="_translate._currentLang === 'en'"
        onclick="window.open('https://publications.iadb.org/publications/portuguese/document/Relatorio-de-Ciberseguranca-2020-riscos-avancos-e-o-caminho-a-seguir-na-America-Latina-e-Caribe.pdf')">
        <i class="fa fa-download fa-lg" aria-hidden="true"></i> {{'descargarPortugues' | translate}} 
      </button>
    </div>
  </div>
</div>
