import { Component, OnInit } from '@angular/core';
import { CountryService, Country } from '../../../services/country.service'
import { CountryInfoService, CountryInfo } from '../../../services/countryInfo.service'
import { TranslateService } from '../../../services/translateService'
import {ExcelService} from '../../../services/excel.service';

declare var $ :any;

@Component({
  selector: 'app-info-country-selected',
  templateUrl: './info-country-selected.component.html',
  styleUrls: ['./info-country-selected.component.css']
})
export class InfoCountrySelectedComponent implements OnInit {
  colorSelected = "#5c95e0";
  colorUnSelected = "black";

  ngOnInit() {}

  constructor(public _countryService: CountryService,
              public _countryInfoService: CountryInfoService,
              public _translate: TranslateService,
              public _excelService:ExcelService) {
  }

  deleteCountrySelected(e, iso:string, idx: number) {
    this._countryService.deleteCountrySelected(iso);
  }

  getUrlFlat(iso: string){
    if(!iso) iso = "NONE"
    return "assets/img/banderas/" + iso + ".png";
  }

  exportAsXLSX() {
    let isos_alpha2: string[] = this._countryInfoService.countriesInfoSelected.map( c => c.iso_alpha2 );
    this._excelService.exportAsExcelFile(isos_alpha2, [], 'Cybersecurity');
  }

  isCurrentLang(lang: string) {
      return lang === this._translate.currentLang;
  }

  changeCountryInfoSelected(iso: string){
    this._countryInfoService.allCountriesInfo.forEach(countryInfo => {
      if(countryInfo.iso_alpha2 == iso) this._countryInfoService.countryInfoSelected = countryInfo
    })
  }

  updatePopupInfo(){
    this._countryInfoService.popupCountriesInfoSelected = [];
    this._countryInfoService.countriesInfoSelected.forEach(countryInfo =>{ //se deja de primero el seleccionado del que se quiere ver el detalle
      if(countryInfo.iso_alpha2 == this._countryInfoService.countryInfoSelected.iso_alpha2)
      this._countryInfoService.popupCountriesInfoSelected.push(countryInfo)
    })
    this._countryInfoService.countriesInfoSelected.forEach(countryInfo =>{// se agregan los otros paises seleccioandos
      if(countryInfo.iso_alpha2 != this._countryInfoService.countryInfoSelected.iso_alpha2)
      this._countryInfoService.popupCountriesInfoSelected.push(countryInfo)
    })

  }

}
