import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatusComponentsService {


  mapaComponentLoad: boolean=false;

  constructor() { }


  markMapaComponentLoaded(){
    this.mapaComponentLoad = true;
  }

  markMapaComponentUnloaded(){
    this.mapaComponentLoad = false;
  }

  isMapaComponentLoad(){
    return this.mapaComponentLoad;
  }
}
