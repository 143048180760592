import { Component, OnInit } from '@angular/core';
import { CyberSecurityContributorService } from '../../services/cyberSecurityContributor.service';
import { TranslateService } from '../../services/translateService';

@Component({
  selector: 'app-contributors',
  templateUrl: './contributors.component.html',
  styleUrls: ['./contributors.component.css']
})
export class ContributorsComponent implements OnInit {
  public contributors:any=[];

  constructor(public _cybersecurityService : CyberSecurityContributorService,
              public _translate: TranslateService) { }

  ngOnInit() {
  this.getContributors();
  }

  getContributors(){
    if(JSON.stringify(this.contributors)==="[]"){
      this._cybersecurityService.getContributors().subscribe(result=> {
        this.contributors=result;
      });
    }
  }

  isCurrentLang(lang: string) {
      return lang === this._translate.currentLang;
  }
}
