<div class="modal-content">
  <!-- modal-header-->
  <div class="modal-header size-13">
    <p class="modal-title" id="exampleModalLongTitle">
      <img src="/assets/img/home/+info.png" height="33" />
      <b>{{ "informacionDePaises" | translate }}</b>
    </p>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- End modal-header-->
  <!-- modal-body-->
  <div class="modal-body modal-body-popup size-11">
    <div
      class="row"
      *ngFor="
        let countryInfo of _countryInfoService.popupCountriesInfoSelected;
        let i = index
      "
    >
      <div class="col-12 mr-1 pr-0">
        <button
          class="buttonSimple pl-0"
          data-placement="left"
          data-toggle="collapse"
          [attr.data-target]="'#countryInfo1' + i"
          (click)="collapseButton(countryInfo.iso_alpha2)"
        >
          <table class="tableModalIcons">
            <tr>
              <td style="min-width: 150px; max-width: 150px;">
                <!--Nombre pais-->
                <tr class="align-middle">
                  <td style="min-width: 9px; max-width: 9px;">
                    <div
                      *ngIf="
                        _countryInfoService.countryInfoSelected.iso_alpha2 !=
                        countryInfo.iso_alpha2
                      "
                    >
                      <i class="fas fa-caret-right"></i>
                    </div>
                    <div
                      *ngIf="
                        _countryInfoService.countryInfoSelected.iso_alpha2 ==
                        countryInfo.iso_alpha2
                      "
                    >
                      <i class="fas fa-caret-down"></i>
                    </div>
                  </td>
                  <td>
                    <img
                      [src]="getUrlFlat(countryInfo.iso_alpha2)"
                      width="35"
                      height="35"
                      class="px-0 mx-1"
                    />
                  </td>
                  <td align="left">
                    <b>{{
                      countryInfo.nameEs
                        | translateCountryName
                          : countryInfo.nameEn
                          : countryInfo.nameEs
                    }}</b>
                  </td>
                </tr>
              </td>
              <td>
                <!--poblacion total-->
                <tr>
                  <th rowspan="2">
                    <img
                      src="assets/img/home/poblacion.png"
                      class="mr-2"
                      width="35"
                      height="35"
                    />
                  </th>

                  <td align="left">
                    <b>{{ "poblacionTotalDelPais" | translate }}</b>
                  </td>
                </tr>
                <tr>
                  <td align="left">{{ countryInfo.totalPopulation }}</td>
                </tr>
              </td>
              <td>
                <!--penetracion a internet-->
                <tr>
                  <th rowspan="2">
                    <img
                      src="assets/img/home/penetracion-internet.png"
                      class="mr-2"
                      width="35"
                      height="35"
                    />
                  </th>

                  <td align="left">
                    <b>{{ "personasAccesoInternet" | translate }}</b>
                  </td>
                </tr>
                <tr>
                  <td align="left">{{ countryInfo.peopleWithInternet }}</td>
                </tr>
              </td>
              <td>
                <!--con celular-->
                <tr>
                  <th rowspan="2">
                    <img
                      src="assets/img/home/con-celular.png"
                      class="mr-2"
                      width="35"
                      height="35"
                    />
                  </th>

                  <td align="left">
                    <b>{{ "personasAccesoCelular" | translate }}</b>
                  </td>
                </tr>
                <tr>
                  <td align="left">{{ countryInfo.peopleWithPhones }}</td>
                </tr>
              </td>
              <td>
                <!--acceso a internet-->
                <tr>
                  <th rowspan="2">
                    <img
                      src="assets/img/home/acceso-internet.png"
                      class="mr-2"
                      width="35"
                      height="35"
                    />
                  </th>

                  <td align="left">
                    <b>{{ "penetracionInternet" | translate }}</b>
                  </td>
                </tr>
                <tr>
                  <td align="left">{{ countryInfo.internetPenetration }}</td>
                </tr>
              </td>
            </tr>
          </table>
        </button>
      </div>
      <div class="col-12">
        <pre
          [ngClass]="
            _countryInfoService.countryInfoSelected.iso_alpha2 !=
            countryInfo.iso_alpha2
              ? 'shortInformation'
              : ''
          "
          *ngIf="this._translate.currentLang == 'es'"
          ALIGN="left"
          >{{ countryInfo.infoEs }}</pre
        >
        <pre
          [ngClass]="
            _countryInfoService.countryInfoSelected.iso_alpha2 !=
            countryInfo.iso_alpha2
              ? 'shortInformation'
              : ''
          "
          *ngIf="this._translate.currentLang == 'en'"
          ALIGN="left"
          >{{ countryInfo.infoEn }}</pre
        >
        <button
          class="btn btn-link px-0"
          style="color: #6b737a;"
          data-placement="left"
          (click)="collapseButton(countryInfo.iso_alpha2)"
          *ngIf="
            _countryInfoService.countryInfoSelected.iso_alpha2 !=
            countryInfo.iso_alpha2
          "
        >
          {{ "leerMas" | translate }}
        </button>
        <hr />
      </div>
    </div>
  </div>
  <!-- End modal-body -->
</div>
<!-- End modal-content -->
