export var GLOBAL = {
    //url: 'http://backend:3789/api',
    url: 'https://api.cybersecurityobservatory.org/api',
    // url: 'https://api-observatorio.csirtamericas.org/api',
    //url: 'http://localhost:3789/api',
    //url: 'http://104.238.235.164:3789/api',
    captchaSiteKey: "6LcwEA0cAAAAAEfouAdRIoEkFrO--lCFEA5mnMGm",
    niveles_madurez : [
      { nivel_madurez: '1', description: 'Inicial'},
      { nivel_madurez: '2', description: 'Formativo'},
      { nivel_madurez: '3', description: 'Establecido'},
      { nivel_madurez: '4', description: 'Estratégico'},
      { nivel_madurez: '5', description: 'Dinámico'}
    ]
}
