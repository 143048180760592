import { Component, OnInit, ViewChild } from '@angular/core';
import { CountryService, Country } from '../../services/country.service';
import { CountryInfoService } from '../../services/countryInfo.service';
import { TranslateService } from '../../services/translateService';
import { StatusComponentsService } from '../../services/statusComponents.service';
import { MapaComponent } from './mapa/mapa.component' 
import { MenuHomeComponent } from './menu-home/menu-home.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild(MapaComponent) mapa: MapaComponent;
  @ViewChild(MenuHomeComponent) menu: MenuHomeComponent;

  countries: Array<Country> = [];
  nameCountryToFilter: string;

  constructor(public _countryService: CountryService,
              public _countryInfoService: CountryInfoService,
              public _translateService: TranslateService,
              public _statusComponentsService : StatusComponentsService ) {}

  ngOnInit() {
    if (this._countryService.allCountries.length === 0) {
      this._countryInfoService.getAllCountriesInfo().subscribe(countries => {});
      this._countryService.getAllCountries().subscribe(countries => {
        this.countries = this._countryService.allCountries;
      });
    } else {
        this.countries = this._countryService.allCountries;
    }
  }

  selectCountry(e, iso: string) {
    this._countryService.selectCountry(iso);
  }

  deleteCountrySelected(e, iso: string) {
    this._countryService.deleteCountrySelected(iso);
  }

  cleanSelectedCountries(){
    this.nameCountryToFilter = "";
    this.onSearchChange(this.nameCountryToFilter);
    this._countryService.cleanSelectedCountries();
  }
  
  onSearchChange(value: string) {
    this.countries = this._countryService.allCountries.filter(
      country => {
        let result;
        switch ( this._translateService.currentLang ) {
          case 'en': {
            result = country.nameEn.toLowerCase().startsWith( value.toLowerCase() );
            break;
          }
          default: {
            result = country.nameEs.toLowerCase().startsWith( value.toLowerCase() );
            break;
          }
        }
        return result;
      }
    );
  }
  
  checkCountryByMapa(iso:string){
    this.menu.selectCountry(iso)
  }

  paintCountry(iso:string){
    if (this._statusComponentsService.isMapaComponentLoad())
      this.mapa.paintCountryAfterClickCountryByCheckBox(iso)
  }

  cleanMap(){
    if (this._statusComponentsService.isMapaComponentLoad())
      this.mapa.resetMap();
  }

  setShowDetail(){
    this._countryService.showDetail()
  }
}