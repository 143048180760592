import { Component, OnInit } from '@angular/core';
import {ExcelService} from '../../services/excel.service';
import { CountryService } from '../../services/country.service'
import { CountryInfoService, CountryInfo } from '../../services/countryInfo.service'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {

  itemActivo: string = "Inicio";
  countriesInfoDowload:CountryInfo[]=[];

  public itemsMenu:menu[] = [
    { item: "Inicio", image: "assets/img/menu/home.png", imageActive: "assets/img/menu/home2.png", rlink:"home", href:""},
    { item: "AcercaDe", image: "assets/img/menu/acerca-de.png", imageActive: "assets/img/menu/acerca-de2.png", rlink:"about", href:""},
    { item: "InfoInstitucional", image: "assets/img/menu/info.png", imageActive: "assets/img/menu/info2.png", rlink:"institutionalinformation", href:""},
    { item: "Creditos", image: "assets/img/menu/credits.png", imageActive: "assets/img/menu/credits2.png", rlink:"credits", href:""},
    { item: "Contribuidores", image: "assets/img/menu/contribuidores.png", imageActive: "assets/img/menu/contribuidores2.png", rlink:"contributors", href:""},
    { item: "Contacto", image: "assets/img/menu/contact.png", imageActive: "assets/img/menu/contact2.png", rlink:"contact", href:""},
    { item: "ReporteFinal", image: "assets/img/menu/report.png", imageActive: "assets/img/menu/report2.png", rlink:"final-report", href:""}
    // { item: "BdPaises", image: "assets/img/menu/xls.png", imageActive: "assets/img/menu/xls.png", rlink:"", href:""}
  ]

  constructor(public _countryService: CountryService,
              public _excelService:ExcelService,
              public _countryInfoService: CountryInfoService) {}

  ngOnInit() {}

  isActive(item: string) {
    if(item == this.itemActivo) return true;
    else return false;
  }

  setItemActive(item: string){
    this.itemActivo=item;
    if(item =='BdPaises') {
      this.exportAsXLSX();
    }    
  }

  exportAsXLSX() {
    if (this._countryService.allCountries.length === 0) {
      this._countryService.getAllCountries().subscribe(countries => {
        let isos_alpha2: string[] = this._countryService.allCountries.map( c => c.iso_alpha2 );
        this._excelService.exportAsExcelFile(isos_alpha2, [], 'Cybersecurity');
      });
    } else {
      let isos_alpha2: string[] = this._countryService.allCountries.map( c => c.iso_alpha2 );
      this._excelService.exportAsExcelFile(isos_alpha2, [], 'Cybersecurity');
    }
  }
}

export interface menu{
  item: string;
  image: string;
  imageActive: string;
  rlink: string;
  href: string
}
