<div class="margin-top">
  <h3>{{'conTituloContact' | translate}}</h3>
  <br>
  <div class="row">
    <div class="col-md-5">
      <h6 class="font-weight-bold">{{'conSubTituOEA' | translate}}</h6>
      <pre>17th St. and Constitution Ave., N.W.</pre>
      <pre>Washington, D.C., 20006-4499</pre>
      <pre> {{ 'contacEstadosUnidos' | translate}} </pre>
      <pre>Phone: 1 (202) 370 5000</pre><br>

      <h6 class="font-weight-bold">{{'conSubTituOEA2' | translate}}</h6>
      <pre>IDB Headquarters</pre>
      <pre>1300 new York Avenue, N.W.</pre>
      <pre>Washington, D.C., 20577, </pre>
      <pre>{{ 'contacEstadosUnidos' | translate}}</pre>
      <pre>Phone: (202) 623 1000</pre>
      <br>
    </div>
    <div class="col-md-7 pl-0">
      <h6 class="font-weight-bold">{{'conTituloFormCon' | translate}}</h6>
      <form #myForm="ngForm" (ngSubmit)="sendContactEmail(); myForm.reset()" ngNativeValidate>
        <div class="form-group row">
          <div class="col-6">
            <input type="text" class="form-control" placeholder="{{'contacFormNom' | translate}}" name="name" required
              [(ngModel)]="contact.name">
          </div>
          <div class="col-6 pl-0">
            <input type="email" class="form-control" placeholder="Email" name="email" required
              [(ngModel)]="contact.email">
          </div>
        </div>
        <div class="form-group">
          <textarea class="form-control" id="exampleFormControlTextarea1"
            placeholder="{{'contacFormConsulta' | translate}}" rows="3" name="query" required
            [(ngModel)]="contact.query"></textarea>
        </div>
        <div>
          <re-captcha  name="captcha" 
            [siteKey]="siteKey" required [(ngModel)]="contact.captcha"></re-captcha>
        </div>

        <button type="submit" class="btn btn-primary mt-2" > {{'contacFormEnviar' | translate}} </button>
      </form>

      <div class="alert alert-success" role="alert" style="display: none" id="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <strong>{{'mensajeOk' | translate}}</strong>
      </div>
      <div class="alert alert-warning" role="alert" style="display: none" id="alert-warning">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
            aria-hidden="true">&times;</span></button>
        <strong>{{'mensajeError' | translate}}</strong>
      </div>
    </div>
  </div>
</div>