import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EmailService } from '../../../services/email.service';
import { GLOBAL } from '../../../services/global';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-compartir',
  templateUrl: './compartir.component.html',
  styles: []
})
export class CompartirComponent implements OnInit {

  urlToShare = 'http://observatoriociberseguridad.org';
  urlToShareTwiter = 'https://twitter.com/share?url=' + this.urlToShare
  urlToShareFacebook = "http://www.facebook.com/sharer.php?u=" + this.urlToShare
  baseCompartir = { email: "", comment: "", captcha: "" };
  siteKey = "";
  compartir = { ...this.baseCompartir };

  ngOnInit() { this.siteKey = GLOBAL.captchaSiteKey; }

  constructor(public _emailService: EmailService) { }

  sendShareEmail() {
    this._emailService.sendShareEmail(this.compartir).subscribe((result: any) => {
      this.compartir = { ...this.baseCompartir };
      if (result.success) {
        $('#alert').fadeIn(1000);
        setTimeout(function () { $('#alert').fadeOut(1000); }, 2000);
      }
    }, (error) => {
      console.error(error);
      $('#alert-warning').fadeIn(1000);
      setTimeout(function () { $('#alert-warning').fadeOut(1000); }, 2000);
    }
    );
  }

}
