import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../services/country.service'
import { TranslateService } from '../services/translateService'

@Pipe({
  name: 'translateCountryName',
  pure: false
})

export class TranslateCountryNamePipe implements PipeTransform {

  constructor(public _translate: TranslateService) { }

  transform(name: string, nameEn:string, nameEs:string): string {
      if(this._translate.currentLang =="en"){
        return nameEn;
      }
      if (this._translate.currentLang=="es")
        return nameEs;
      }
}
