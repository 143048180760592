import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from './global';

@Injectable()
export class EmailService {

  constructor(public http: HttpClient) {}
  urlContat = `${GLOBAL.url}/sendEmailContact`;
  urlShare = `${GLOBAL.url}/sendEmailShare`;
  headers = new HttpHeaders()

  sendContactEmail(params:object){
    return this.http.post(this.urlContat, params,  {headers: this.headers})
  }

  sendShareEmail(params:object){
    return this.http.post(this.urlShare, params,  {headers: this.headers})
  }

}
