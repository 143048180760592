import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../services/translateService';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public translatedText: string;
  public supportedLanguages: any[];

  constructor(public _translate: TranslateService) { }

  ngOnInit() {
      this.supportedLanguages = [
      { display: 'English', value: 'en' },
      { display: 'Español', value: 'es' }
      ];
      this.selectLang('es');
  }

  isCurrentLang(lang: string) {
      return lang === this._translate.currentLang;
  }

  selectLang(lang: string) {
      this._translate.use(lang);
      this.refreshText();
  }

  refreshText() {
      this.translatedText = this._translate.instant('hello world');
  }

  isSelected(value: string){
    return (value=== this._translate.currentLang) 
  }

}
