import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GLOBAL } from './global';
@Injectable({
  providedIn: 'root'
})
export class CaptchaService {

  constructor(private http: HttpClient) { }
  urlToken = `${GLOBAL.url}/token_validate`;
  sendToken(token){
    // return this.http.post<any>("/token_validate", {recaptcha: token})
    return this.http.post(this.urlToken, {recaptcha: token})
  }
}
