<div class="container-fluid">
    <div> <!--1 paises a comparar-->
        <div class="row">
            <p class="mb-4 ml-2 size-13">{{'homeParrafo1' | translate}}</p>
        </div>
        <div class="row">
            <p class="size-13 mb-2 interlineado"><strong><span class="capitalLetter">1</span>{{'opcionToCompare' | translate}}</strong></p>
        </div>
        <div class="row mb-2"> <!--Opciones: comparar paises en un año o comparar pais en varios años-->
            <button (click)="compareOneCountry(false)" type="button" [ngClass]="!_countryService.compareOneCountryByYears ? 'btn btn-filters m-2 active' : 'btn btn-filters m-2'"> 
               {{'compararPaises' | translate}}
            </button>
            <button (click)="compareOneCountry(true)"  type="button" [ngClass]="_countryService.compareOneCountryByYears ? 'btn btn-filters m-2  active' : 'btn btn-filters m-2'">
                {{'compararPaisPorAnio' | translate }}
            </button>
        </div>
        <div class="row size-11" *ngIf="!_countryService.compareOneCountryByYears"> <!--Checkbox-->
            <div class="radiobuttons-container mx-2">
                <p class="form-check size-12 mb-0" *ngFor="let country of _countryService.allCountries | groupBy: 'iso_alpha2'">
                    <input class="form-check-input" [checked]=country.selected (change)="selectCountry(country.iso_alpha2)" id="{{country.iso_alpha2}}" type="checkbox"> 
                    <label class="form-check-label ml-1" for="{{country.iso_alpha2}}" >{{country.nameEs | translateCountryName: country.nameEn :country.nameEs}}</label>
                </p>
            </div> 
        </div>
        <div class="row size-11" *ngIf="_countryService.compareOneCountryByYears"><!--Select Options-->
            <select (change)="selectCountry(_countryService.countrySelected)" [(ngModel)]="_countryService.countrySelected" class="custom-select mx-2">
                <option [value]=null>{{ 'seleccionePais' | translate }}</option>
                <option *ngFor="let country of _countryService.allCountries | groupBy: 'iso_alpha2'" [value]="country.iso_alpha2" selected=country.selected>
                    {{country.nameEs | translateCountryName: country.nameEn :country.nameEs}}
                </option>
            </select>
        </div>
    </div>
    
    <div class="mb-0 mt-4"><!--2 año a comparar-->
        <div class="row" *ngIf="!_countryService.compareOneCountryByYears">
            <p class="size-13 mb-3 interlineado"><strong><span class="capitalLetter">2</span>{{'avisoPasoDos' | translate}}</strong></p>
            <select (change)="selectYearToCompare(_countryService.yearSelected)" [(ngModel)]="_countryService.yearSelected" class="custom-select mx-2 size-11">
                <option value=null disabled>{{'seleccioneAnio' | translate}}</option>
                <option *ngFor="let country of _countryService.allCountries | filter : 'selected' : 'true' | groupBy: 'year';" [value]="country.year">
                    <p>{{country.year}}</p>
                </option>
            </select>
        </div>
        <div class="row" *ngIf="_countryService.compareOneCountryByYears">
            <p class="size-13 mb-3 interlineado"><strong><span class="capitalLetter">2</span>{{'avisoPasoDos' | translate}}</strong></p>
            <div class="col-12 size-11">
                <label class="mdl-radio mdl-js-radio mr-2" *ngFor="let country of _countryService.allCountries | filter : 'selected' : 'true' | groupBy: 'year';">
                    <input [checked]="_countryService.isYearChecked(country.year)"  (change)="selectYearToCompare(country.year)" type="checkbox" /> 
                    <span>{{country.year}}</span>
                </label>
            </div>
        </div>
    </div>

    <div class="mb-0 mt-4"><!--3 indicadores-->
        <div class="row">
            <p class="size-13 mb-3 interlineado"><strong><span class="capitalLetter">3</span>{{'avisoPasoTres' | translate}}</strong></p> 
        </div>
        <hr class="mt-0">
        <div class="row subattributescroll">
            <div class="row col-12 pr-0" *ngFor="let attribute of _attributeService.attributes; let i=index;">
                <div class="row col-12 pr-0 size-12">                   
                    <div class="mb-0 px-0" data-toggle="collapse" [attr.data-target]="'#attribute' + i" aria-expanded="false">
                        <input type="checkbox" [checked]=attribute.selected (change)="selectAttributeToCompare(attribute.name)" id="{{attribute.name}}">  
                        <i class="fas fa-caret-right mx-1 size-14"></i> 
                        <i class="fas fa-caret-down mx-1 size-14"></i> 
                        <b>{{ attribute.name | translate}}</b>
                    </div>
                </div>
                <div class="row collapse ml-4 radiobuttons-container-subAttributes" id="attribute{{i}}">
                    <p class="form-check size-13 mb-0" *ngFor="let subAttribute of attribute.subAttributes; let j=index;">
                        <input class="form-check-input" [checked]=subAttribute.selected (change)="selectSubAttributeToCompare(subAttribute.name)" id="{{subAttribute.name}}{{j}}" type="checkbox"> 
                        <label class="form-check-label" for="{{subAttribute.name}}{{j}}" >{{ subAttribute.name | translate }}</label>
                    </p>
                </div>
            </div>
        </div>
        <hr class="mt-0">
    </div>

    <!-- Botones de limpiar y ver resultados -->
    <div class="row">
        <button (click)="cleanAllFilters()" style="margin: 10px;color: #6b737a" type="button" class="btn size-13">
            <span>{{'homeBtnCleanCountriesSelection' | translate}}</span>
        </button>
        <button type="image"  (click)="_countryService.showDetail()" class="buttonSimple" > 
            <img src="/assets/img/home/ver-resultados-en.jpeg" [hidden]="_translate._currentLang==='es'">
            <img src="/assets/img/home/ver-resultados-es.jpeg" [hidden]="_translate._currentLang==='en'">
        </button>
    </div>

    <!-- Modal No hay paises seleccionados-->
    <div class="modal"  id="myModal2" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content size-12">
                <div class="modal-header pb-0">
                    <tr>
                        <td><img src="assets/img/home/aviso.png" onError="this.src='assets/img/banderas/NONE.png';" width="30" height="30" class="mr-2"></td>
                        <td><P><b>{{ 'aviso' | translate }}</b></P></td>
                    </tr>
                    
                    
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <strong>
                    <p>{{'aviso1'| translate}} </p>
                    <p class="mb-0"><span class="letterBlue">1.</span> {{'avisoPasoUno'| translate}}</p>
                    <p class="mb-0"><span class="letterBlue">2.</span> {{'avisoPasoDos'| translate}}</p>
                    <p><span class="letterBlue">3.</span> {{'avisoPasoTres'| translate}}</p>
                    <p>{{'aviso2'| translate}}</p>
                    </strong>
                </div>
            </div>
        </div>
    </div>
</div>