import { Component, OnInit } from '@angular/core';
import { CyberSecurityCreditService } from '../../services/cyberSecurityCredits.service';
import { TranslateService } from '../../services/translateService';


@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.css']
})
export class CreditsComponent implements OnInit {

  public credits:any=[];

  constructor(public _cybersecurityService : CyberSecurityCreditService,
              public _translate: TranslateService) { }

  ngOnInit() {
    this.getCredits();
  }

  getCredits(){
    if(JSON.stringify(this.credits)==="[]"){
      this._cybersecurityService.getCredits().subscribe(result=> {
        this.credits=result;
      });
    }
  }

  isCurrentLang(lang: string) {
      return lang === this._translate.currentLang;
  }
}
