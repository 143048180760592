import { Component, OnInit } from '@angular/core';
import { CyberSecurityInstitutionalService } from '../../services/cyberSecurityInstitutionalInfo.service';
import { TranslateService } from '../../services/translateService';

@Component({
  selector: 'app-institutionalinformation',
  templateUrl: './institutionalinformation.component.html',
  styles: []
})
export class InstitutionalInformationComponent implements OnInit {

  public institutionalinformation:any=[];

  constructor(public _cybersecurityService : CyberSecurityInstitutionalService,
              public _translate: TranslateService) { }

  ngOnInit() {
    this.getInstitutionalInformation();
  }

  getInstitutionalInformation(){
    if(JSON.stringify(this.institutionalinformation)==="[]"){
      this._cybersecurityService.getInstitucionalInformation().subscribe(result=> {
        this.institutionalinformation=result;
      });
    }
  }

  isCurrentLang(lang: string) {
      return lang === this._translate.currentLang;
  }

}
