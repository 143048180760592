<div class="container-fluid pl-0">
  <hr class="htHorizontal" />

  <div
    class="row center"
    [hidden]="_countryInfoService.countriesInfoSelected.length > 0"
    style="text-align: center;"
  >
    <table>
      <tr>
        <td>
          <img
            src="assets/img/home/selecione-variables.png"
            onError="this.src='assets/img/banderas/NONE.png';"
            width="60"
            height="60"
            class="mr-2"
          />
        </td>
        <td>
          <p class="mb-0">
            <span class="letterBlue size-19">{{
              "apliqueFiltros" | translate
            }}</span>
          </p>
        </td>
      </tr>
    </table>
  </div>

  <div
    class="row ml-2 size-11"
    [hidden]="_countryInfoService.countriesInfoSelected.length == 0"
  >
    <div class="row col-12 px-0">
      <!-- ROW 1-->
      <div class="col-md-3 col-sm-10 px-0">
        <p class="mx-0 my-0">
          <b>{{ "seleccioneParaVerDatos" | translate }}</b>
        </p>
      </div>
      <div class="col-9 px-0 mb-1">
        <!-- Botones info, descargar, compartir, ver resultados-->
        <button
          class="buttonSimple pl-0"
          data-placement="left"
          data-target="#modalDetailsCountries"
          data-toggle="modal"
          (click)="updatePopupInfo()"
        >
          <img src="/assets/img/home/+info.png" height="25" />
        </button>
        <button class="buttonSimple pl-0" (click)="exportAsXLSX()">
          <img
            src="/assets/img/home/descargar-xls-es.png"
            [hidden]="_translate._currentLang === 'en'"
            height="25"
          />
          <img
            src="/assets/img/home/descargar-xls-en.png"
            [hidden]="_translate._currentLang === 'es'"
            height="25"
          />
        </button>
        <!-- <button
          class="buttonSimple pl-0"
          data-target="#modalShare"
          data-toggle="modal"
        >
          <img
            src="/assets/img/home/compartir-es.png"
            [hidden]="_translate._currentLang === 'en'"
            height="25"
          />
          <img
            src="/assets/img/home/compartir-en.png"
            [hidden]="_translate._currentLang === 'es'"
            height="25"
          />
        </button> -->
        <button
          class="buttonSimple pl-0"
          (click)="_countryService.showDetail()"
        >
          <img
            src="/assets/img/home/ver-resultados-es.png"
            [hidden]="_translate._currentLang === 'en'"
            height="25"
          />
          <img
            src="/assets/img/home/ver-resultados-en.png"
            [hidden]="_translate._currentLang === 'es'"
            height="25"
          />
        </button>
      </div>
      <div class="col-12 mx-0"><hr class="my-0" /></div>
    </div>

    <div class="row px-0 mt-2 size-11">
      <!-- ROW 2-->
      <div class="col-12 col-lg-3 px-0 mb-3">
        <!--Listado de paises con scroll-->
        <div class="countryscroll">
          <div
            *ngFor="let country of _countryInfoService.countriesInfoSelected ;index as idx"
            class="mb-1 linkCountry"
          >
            <a (click)="changeCountryInfoSelected(country.iso_alpha2)">
              <table>
                <tr>
                  <td>
                    <button
                      type="button"
                      (click)="
                        deleteCountrySelected($event, country.iso_alpha2, idx)
                      "
                      class="close float-left mt-2 mr-1 size-16"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </td>
                  <td>
                    <img
                      [src]="getUrlFlat(country.iso_alpha2)"
                      onError="this.src='assets/img/banderas/NONE.png';"
                      height="30"
                    />
                  </td>
                  <td>
                    <b
                      [className]="
                        country.iso_alpha2 ==
                        _countryInfoService.countryInfoSelected.iso_alpha2
                          ? 'blue'
                          : 'black'
                      "
                      >{{
                        country.nameEn
                          | translateCountryName: country.nameEn:country.nameEs
                      }}</b
                    >
                  </td>
                </tr>
              </table>
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-9 px-0 mb-1">
        <!--Informacion de paises seleccionados-->
        <div class="row mr-0">
          <div class="col-12 col-lg-8 pl-4 pr-0">
            <img
              [src]="
                getUrlFlat(_countryInfoService.countryInfoSelected.iso_alpha2)
              "
              onError="this.src='assets/img/banderas/NONE.png';"
              height="30"
            />
            <label class="ml-1 blue">{{
              _countryInfoService.countryInfoSelected.nameEn
                | translateCountryName
                  : _countryInfoService.countryInfoSelected.nameEn
                  : _countryInfoService.countryInfoSelected.nameEs
            }}</label>
            <div class="shortInformation" [hidden]="!isCurrentLang('es')">
              {{ _countryInfoService.countryInfoSelected.infoEs }}
            </div>
            <div class="shortInformation" [hidden]="!isCurrentLang('en')">
              {{ _countryInfoService.countryInfoSelected.infoEn }}
            </div>
            <div class="margin px-0">
              <button
                class="btn btn-link size-11 pl-1 blue"
                data-placement="left"
                data-target="#modalDetailsCountries"
                data-toggle="modal"
                (click)="updatePopupInfo()"
              >
                {{ "leerMas" | translate }}
              </button>
            </div>
          </div>
          <div class="col-12 col-lg-4 px-0 pl-4 pl-lg-2">
            <!--Informacion de poblacion, penetracion internet... -->
            <table>
              <tr>
                <td><img src="assets/img/home/poblacion.png" width="34" /></td>
                <td valign="middle" style="padding: 6px;">
                  <p class="my-0">
                    <b>{{ "poblacionTotalDelPais" | translate }} </b>
                  </p>
                  <p class="my-0">
                    {{
                      _countryInfoService.countryInfoSelected.totalPopulation
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    src="assets/img/home/penetracion-internet.png"
                    width="34"
                  />
                </td>
                <td valign="middle" style="padding: 6px;">
                  <p class="my-0">
                    <b>{{ "personasAccesoInternet" | translate }} </b>
                  </p>
                  <p class="my-0">
                    {{
                      _countryInfoService.countryInfoSelected.peopleWithInternet
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <img src="assets/img/home/con-celular.png" width="34" />
                </td>
                <td valign="middle" style="padding: 6px;">
                  <p class="my-0">
                    <b>{{ "personasAccesoCelular" | translate }} </b>
                  </p>
                  <p class="my-0">
                    {{
                      _countryInfoService.countryInfoSelected.peopleWithPhones
                    }}
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <img src="assets/img/home/acceso-internet.png" width="34" />
                </td>
                <td valign="middle" style="padding: 6px;">
                  <p class="my-0">
                    <b>{{ "penetracionInternet" | translate }} </b>
                  </p>
                  <p class="my-0">
                    {{
                      _countryInfoService.countryInfoSelected
                        .internetPenetration
                    }}
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal compartir -->
<div
  class="modal fade"
  id="modalShare"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content size-11">
      <div class="modal-header">
        <p style="color: #545454;" class="my-0">
          {{ "compartir" | translate }}
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <app-compartir></app-compartir>
        </div>
      </div>
      <!-- modal-body -->
    </div>
  </div>
</div>

<!--Modal info - detalle de los paises -->
<div class="modal" id="modalDetailsCountries" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <app-detalle-pais></app-detalle-pais>
  </div>
</div>
