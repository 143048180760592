<nav class="navbar navbar-expand-lg navbar-light">
  <div class="row container-fluid pt-2 pb-3">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 align-content-lg-start pl-0">
      <a href="/">
        <img
          src="assets/img/header/ciberSeguridad-es.png"
          [hidden]="!isCurrentLang('es')"
          width="400px"
          class="d-inline-block align-top"
          class="img-responsive img-fluid"
        />
        <img
          src="assets/img/header/ciberSeguridad-en.png"
          [hidden]="!isCurrentLang('en')"
          width="400px"
          class="d-inline-block align-top"
          class="img-responsive img-fluid"
        />
      </a>
    </div>

    <div class="col-5 col-xs-5 col-sm-5 col-md-2 col-lg-2 px-0 mx-0 mt-2 text-right">
      <img
        src="assets/img/header/logo-BOD-es.png"
        [hidden]="!isCurrentLang('es')"
        class="d-inline-block align-items-start"
        class="img-responsive img-fluid max-width: 50px; height: auto;"
      />
      <img
        src="assets/img/header/logo-BOD-en.png"
        [hidden]="!isCurrentLang('en')"
        class="d-inline-block align-items-start"
        class="img-responsive img-fluid max-width: 50px; height: auto;"
      />
    </div>

    <div class="px-0">
      <hr
        style="
          width: 2px;
          height: 25px;
          background-color: #a8a8a8;
          margin-top: 10px;
          margin-left: -25px;
        "
      />
    </div>

    <div class="col-4 col-xs-4 col-sm-4 col-md-2 col-lg-2 px-0 mx-0 mt-2">
      <img
        src="assets/img/header/logo-OEA-es.png"
        [hidden]="!isCurrentLang('es')"
        class="d-inline-block align-items-end"
        class="img-responsive img-fluid max-width: 300px; height: auto;"
      />
      <img
        src="assets/img/header/logo-OEA-en.png"
        [hidden]="!isCurrentLang('en')"
        class="d-inline-block align-items-end"
        class="img-responsive img-fluid max-width: 300px; height: auto;"
      />
    </div>

    <div class="col-2 col-xs-2 col-sm-2 col-md-1 col-lg-1 pl-2 pr-0 mx-0 size-13">
      <ul class="px-0">
        <li *ngFor="let lang of supportedLanguages">
          <a
            (click)="selectLang(lang.value)"
            [className]="
              isSelected(lang.value) ? 'lenguageSelected' : 'lenguageNomal'
            "
          >
            {{ lang.display }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
